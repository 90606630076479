import React from 'react'
import './Rajasthancompeletetour.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import Enquiryform from '../components/Enquiryform'


const Rajasthancompeletetour = () => {
  return (
      <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  className='rctpackageheading' >
    Rajasthan Complete Tour (12 Nights/ 13Days)
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span  className='rctlocation'> Delhi » Agra » Jaipur » Bikaner » Jaisalmer » Jodhpur » Udaipur</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/rajasthancomplete.jpeg" class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/desert.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/jaipur2.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 className='rcttouritinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4  className='rctdayone'>DAY-01 : Arrive Delhi </h4>

    <p className='daycontent'>
    Arrival at Delhi, our representative will meet you at arrival hall of IGI airport and assist you in check-in Hotel. Overnight stay at Delhi.</p>
</div>

<br></br>

<div className='contentbox'>
<h4   className='rctdaytwo'>DAY-02 :Delhi Sightseeing</h4>

<p className='daycontent'>After breakfast , full day combined city tour of Old & New Delhi visiting : Birla Temple, India Gate, Parliament & President House, Lotus temple(Monday will be closed), Humayun Tomb, Qutub Tower, Raaj Ghat, Jama Mosque and Red Fort(Monday will be closed). Overnight stay at Delhi.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdaythree'>DAY-03 :Delhi To Agra</h4>

    <p className='daycontent'>

    After breakfast drive to Agra (203 kms / 4 hours) Upon arrival in Agra - check in at the hotel. Later in the day explore Taj Mahal and Red Fort. Overnight stay at Agra. (Every Friday Taj Mahal will be closed, Sunset & Sunrise You Can visit.)</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rctdayfour'>DAY-04 :Agra - Fatehpur Sikri - Bahratpur Bird Sanctuary - Jaipur</h4>

    <p className='daycontent'>

    Approx (250kms/5.5hrs) After breakfast, drive to Jaipur en route visit Fatehpur Sikri and Bharatpur Bird sanctuary. Upon arrival in Jaipur check-in at the hotel. Overnight stay at Jaipur.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdayfive'>DAY-05 :Jaipur
</h4>

    <p className='daycontent'>
 
After breakfast, Enjoy a sightseeing tour of the Pink City of Jaipur visiting Wind Palace, Jantar Mantar (Observatory) , Amber Fort, City Palace, Birla Temple, Ram Niwas Garden and other monuments. Overnight stay at Jaipur.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdaysix'>DAY-06 :Jaipur To Pushkar
</h4>

    <p className='daycontent'>
    
After breakfast proceed for Pushkar. Upon arrival check in at hotel. Afternoon visit Brahma Temple, Pushkar Lake and other attractions. Overnight stay at Pushkar.</p>
</div>
<br></br>
<div className='contentbox'>
<h4   className='rctdayseven'>DAY-07 :Pushkar To Bikaner </h4>

    <p className='daycontent'>
    After breakfast drive to Bikaner (240 kms/5Hrs) Upon arrival in Bikaner , check-in at hotel. Afternoon, City sightseeing tour of Bikaner visiting Jhunagarh Fort, Lalgarh campus and camel breeding farm, this desert town in the north of the state was founded in 1488 by Roa Bikaji, a descendant of the founder of Jodhpur, Jodhaji. Overnight at stay hotel.</p>
</div>

<br></br>

<div className='contentbox'>
<h4   className='rctdayeight'>DAY-08 :Bikaner - Deshnok - Jaisalmer (310kms/6hrs) </h4>
<p className='daycontent'>
After breakfast, drive to Jaisalmer after visiting famous Rat Temple in Deshnok Village. Upon arrival check-in at hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdaynine'>DAY-09  :Jaisalmer</h4>

    <p className='daycontent'>
    
    After breakfast, visit this perfect Rajput walled desert city which is like a golden jewel shimmering above the hard sand. Visit the palaces, the Jain temples and magnificent Havelis or merchant's houses, each richly decorated and carved from golden yellow and also enjoy camel- ride in desert. Overnight stay at hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdayten'>DAY-10 :Jaisalmer - Jodhpur (285kms/5hrs)
</h4>

    <p className='daycontent'>
   
    After early breakfast, drive to Jodhpur. Upon arrival check in at hotel. City tour of Jodhpur - the largest city in Rajasthan after Jaipur, Jodhpur stands at the edge of the Thar Desert. Here you will visit Mehran Garh Fort, Jaswant Thara, Mandore Garden Umaid Bhawan Palace and Museum. Overnight stay at hotel.

</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdayeleven'>DAY-11 :Jodhpur - Ranakpur - Udaipur </h4>

    <p className='daycontent'>
    After breakfast drive to Udaipur (280 kms / 6 hours) en-route visit Ranakpur Jain Temples. Afternoon at leisure. Overnight stay at Udaipur.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdaytweleve'>DAY-12 :Udaipur </h4>

    <p className='daycontent'>
    After breakfast, full day city tour of Udaipur visiting : City Palace, Jagdish Temple, Folk Art Museum, Sahelion Ki Bari ( Queen's friends garden), Pichola Lake and other attractions. Overnight stay at Udaipur.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='rctdaythirteen'>DAY-13 :Udaipur drop -Airport or Railway Station </h4>

    </div>
</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Rajasthancompeletetour