import React from 'react'
import './Royalgujrattour.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import Enquiryform from '../components/Enquiryform'

const Royalgujrattour = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2   className='gujratpackageheading' >
         ROYAL GUJRAT  (11 Nights/ 12 Days)
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span  className='gujratlocation'> Ahmedabad » Poshina » Patan » Dasada » Bhuj » Palanpur »Mount Abu</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/gujrat1.jpg" class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/gujrat2.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/gujrat3.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 style={{ }} className='gujratitinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4  className='gujratday1'>DAY-01 : Pickup from Airport </h4>

    <p className='daycontent'>
    On arrival in Ahmedabad, get greeted by our tour representative who will transfer you to your pre-booked hotel. Check in at the hotel for a relaxing overnight stay.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='gujratday2'>DAY-02 :Ahmedabad</h4>

<p className='daycontent'>In the morning after breakfast, we will leave to visit the Calico Museum- primary textile museum of India, showcasing amazing artworks; Swaminarayan Temple- oldest temple of Swaminarayan. Thereafter, we will proceed to Akshardham Temple (Gandhinagar), a temple dedicated to Swaminarayan, with brilliant architecture spread over and area of 23 acres; and Adalaj Stepwell.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday3'>DAY-03 :Poshina </h4>

    <p className='daycontent'>
    In Poshina you find the Darbargadh Poshina, once a palace, and now a welcoming heritage hotel, with huge gateways, a massive dome, numerous pillars and arches, a pleasant courtyard, gardens, lawns and terraces, owned by the descendants of the Chalukyas, whose empire spread through much of Gujarat and Central India in the 12th century. You also find old Jain sandstone temples of Parshvanath and Neminath and an old Shiva temple.
</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday4'>DAY-04 :Poshina </h4>

    <p className='daycontent' >
    Village Jeep Safari and visit Ambaji Mata Mandir.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday5'>DAY-05 : Patan
</h4>

    <p className='daycontent'>
    Patan, an ancient fortified city, was founded in 745 AD by Vanraj Chavda, the most prominent king of the Chavda Kingdom. He named the city "Anhilpur Patan" or "Anhilwad Patan" after his close friend and Prime Minister Anhil Gadariya. It is a historical place located on the bank of Saraswati River.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday6'>DAY-06 : Dasada </h4>

    <p className='daycontent'>
    On arrival at Dasada, check in the wildlife resort. After lunch, we will explore the wildlife sanctuary for the group of the stale joke brown Asiatic (Wild ass). In the evening we will return back to the wildlife resort for dinner and an overnight stay.</p>
</div>
<br></br>
<div className='contentbox'>
<h4  className='gujratday7'>DAY-07 : Bhuj </h4>

    <p className='daycontent'>
    Bhuj is known for its palaces, built in the traditional Kutchi style. Aina Mahal or the Palace of Mirrors (Old Palace), surrounded by a small, fortified courtyard built in the 18th century, is located in the old part of the city.
</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='gujratday8'>DAY-08 : Bhuj (Rann of Kutch) </h4>

<p className='daycontent'>The Great Rann of Kutch is a salt marsh located in the Thar Desert in the Kutch District of Gujarat, India. It is about 7500 sq km in area and is reputed to be one of the largest salt deserts in the world.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday9'>DAY-09  : Bhuj(Mandavi)</h4>

    <p className='daycontent'>
    The first thing most people think of when they visit Mandvi is visiting the seashore. Mandvi Beach is the closest to the town center, across the bridge to the east side of the river, then down the road past a place called Salaya, accessed from just near the Kashi-Vishvanath Temple (sometimes the beach is called Kashi-Vishvanath Beach.) Wind Farm Beach is 7 km west of town, named for the windmills that line it to generate electricity for the area.
</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday10'>DAY-10 : Palanpur
</h4>

    <p className='daycontent'>
    Mithi Vav, a stepwell is the oldest surviving monument of town. It is situated in the eastern part of the town. The five storey stepwell can be entered from the west. Based on its architectural style.
Palanpur has several temples dedicated to Hinduism and Jainism.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday11'>DAY-11 : Mount Abu </h4>

    <p className='daycontent'>
    Mount Abu is a popular hill station in the Aravalli Range in Sirohi district of Rajasthan state in western India, near the border with Gujarat. The mountain forms a distinct rocky plateau 22 km long by 9 km wide.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gujratday12'>DAY-12 : Mount Abu </h4>

    <p  className='daycontent'>
    Drop at Train Station of Mount Abu.</p>
</div>



</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Royalgujrattour