import React from 'react'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import indiagate from '../rinkuuncle/img/indiagate.jpg'
import JAIPUR from '../rinkuuncle/img/JAIPUR.jpg'
import TAJMAHAL from '../rinkuuncle/img/TAJMAHAL.jpg'
import location from '../rinkuuncle/img/location.png';
import Enquiryform from '../components/Enquiryform'
import './Goldentriangle.css'
const Goldentriangle = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div style={{ borderTop: "1px solid blue",marginBottom: "-20px" , marginTop:"30px"}}></div>

   {/* <!-- [package heading] --> */}
    <h2  className='gtheading' >
         GOLDEN TRIANGLE TOUR  (4 Nights/ 5 Days)
    </h2>

    <p >
        <span ><img src={location} alt=""  style={{  height: "22px",}}></img></span> 

        <span style={{}} className='gtlocation'>Delhi » Jaipur » Bharatpur » Fatehpursikri » Agra » Delhi</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div style={{}} className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade imagediv" data-bs-ride="carousel" >
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src={indiagate} class="d-block w-100 " alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={TAJMAHAL} class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={JAIPUR} class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 className='touritineraryheading'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div  className='contentbox'>
<h4   className='dayone'>DAY-01 : ARRIVAL AT DELHI & CHECK IN AT THE HOTEL</h4>

    <p className="dayonecontent">
    Arriving at Delhi Meet and assist by our representative upon your arrival at the airport.Then drive to your hotel with your driver and car.In the morning take a city tour covering Laxmi Naryan Temple-Place of GOD,India Gate-The memorial of martyrs,Parliament House-The Government headquarters,Lotus Temple,Qutub Minar,In the afternoon take a city tour of delhi covering Jama Masjid-The largest mosque in Asia,Red Fort-Red stone magic,Gandhi memorial-The momoir of the father of nation.In evening enjoy indian treditional and cultural folk dance show.Overnight at hotel.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='daytwo'>DAY-02 </h4>

    <p style={{}} className='daytwocontent'>
    Delhi- Jaipur(265 kms 5hrs drive)Next morning you will pick 9am and proceed for Jaipur(Pink City) on arrival in Jaipur check in at Hotel. In the evening visit Hawa Mahal (Place of winds), and enjoy nice walk through the Pink City .</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='daythree'>DAY-03 </h4>

    <p className='daythreecontent'>
   Jaipur is the center of tourist of the entire world. After breakfast in the morning, you will be taken to Amer Fort with an elephant ride and then after City Palace Museum. In the afternoon enjoy tour Jaigarh Fort, Nahargarh Fort and Galta Temples(Monkey Temple) in Jaipur. Rest of the day is free and optional enjoy Rajasthani style delecious Dinner with Traditional and Cultural Dance of Rajasthan and Pupet Show. Return to the hotel for overnight stay

</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='dayfour'>DAY-04 </h4>

    <p className='dayfourcontent'>
    Jaipur- Bharatpur-Fatehpur Sikri- Agra. (245kms 4hrs drive)Early morning drive for Agra in between we visit world famous Birds Sanctuary and Fatehpur Sikri (Ghost Town).Upon arrival in Agra you will transfer to the hotel. In the evening you will free for leisure activity.Dinner and over night stay at hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='dayfive'>DAY-05 </h4>

    <p className='dayfivecontent'>
    Agra Delhi (210 kms 4/5 hrs drive)Next morning you will visit world famous TajMahal built by king Shahjahan in the memory of his Queen wife Mumtaj Mahal. After that visit Red Fort, and Sikandra.Afternoon drive to Delhi and transfer to hotel/airport/onward destination with sweet memories of Golden Triangle tour with Heritage Tour India.</p>
</div>



</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Goldentriangle