import React from 'react'
import './Romanticrajasthan.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import romanticrajasthan from '../rinkuuncle/img/romanticrajasthan.jpg'
import jaipur3 from '../rinkuuncle/img/jaipur3.jpg'
import palace2 from '../rinkuuncle/img/palace2.jpg'
import location from '../rinkuuncle/img/location.png';
import Enquiryform from '../components/Enquiryform'

const Romanticrajasthan = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  className='romanticpackageheading'>
    Rajasthan Romantic Tour (14 Nights/ 15 Days)
    </h2>

    <p >
        <span ><img src={location} alt=""  style={{  height: "22px",}}></img></span> 

        <span style={{}}className='romanticlocation'>Delhi » Mandawa » Bikaner » Jaisalmer » Jodhpur » Ranakpur » Udaipur » Pushkar » Jaipur » Ranthambhore » Agra » Delhi</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src={romanticrajasthan} class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={jaipur3} class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={palace2} class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3  className='romanticitinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4   className='romanticday1'>DAY-01 : Arrive Delhi </h4>

    <p className='daycontent'>
    Meet and assist by our representative upon your arrival at the airport. Then drive to your hotel with your driver and car. After checkout to hotel, city tour of this imperial British capital of the 20th century introduces you to the Qutub Minar – the tallest free standing minaret in stone in the world; then onto Humayun's tomb – the first Mughal construction in India from 1565 AD. Also see the Rashtrapati Bhawan, the India Gate built in the earlier half of the 20th century. Drive through the old city past the Red Fort and visit the Jama Masjid – the biggest of its kind in India. Here take a rickshaw through the winding streets of the old city to see the life of the people in this part of the world. A perfect introduction of your tour. The rickshaw puller pulls you through the imperial avenue the Chandni Chowk where you will find each and every small business under the sky. Finally, reach Rajghat, the memorial of Mahatma Gandhi, where your chauffeur is waiting for you to take you to your next stop the Birla Mandir, a Hindu temple in activity.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='romanticday2'>DAY-02 :Delhi – Mandawa (260km)</h4>

<p className='daycontent'>
Drive to Mandawa, an erst while fort of Rajput king. Check in to the hotel. The guide takes you to the exquisite village of Shekhawati, here you will be overwhelmed at the sight of the beautiful havelis built in 18th and 19th century. The paintings of deities whom the people worship are worth watching. Walk down the bazaar streets and pick up handicrafts as souvenirs. Also see the painted houses in the region. Overnight at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4 className='romanticday3'>DAY-03 : Mandawa-rat Temple-Bikaner</h4>

    <p className='daycontent'>
    Drive to the city of the best breed camels of the world Bikaner. Visit the relgious wonder of the world Rat Temple, Camel Breeding farms, Junagarh Fort, Lallgarh Palace and Fort Museum. The magnificent palace is fabricated in red sandstone and marble is embellished with mirror work, exquisite carvings and paintings, definitely a worth visiting sight. In the evening you are free to wander in the busy market area. Enjoy shopping at the King Edward memorial the main shopping hub offering diverse variety of products of all ranges, also shop the mouth watering delicacies of Bikaner(spicy snacks and sweets). Stay in a hotel overnight.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='romanticday4'>DAY-04 :Bikaner-Khichan-Jaisalmer </h4>

    <p className='daycontent'>
    Morning depart to Jaisalmer,enroute visit Khichan, Khichan is very famous for migrated crane from Siberia,Upon Arrival first check in the hotel,after check in proceed for sun set point at bada Bagh.Overnight at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='romanticday5'>DAY-05 :Jaisalmer - sight seen - Desert Village Khuri
</h4>

    <p className='daycontent'>
    After breakfast in the hotel,morning hours will be spent in a spectacular visit to the Jain temples inside the Sonar Quila. The palace inside this fort abode a museum showcasing a rich collection of robes and artifacts from the royal family of Jaisalmer. Afterwards, you are guided to the Patwaon ki haveli, the carved archways and intricately designed latticed windows are worth appreciating. The Gadisar Lake will offer you some memorable views. Later in afternoon you will be visiting the beautiful Khuri village. Ride on a camel to reach the sunset point to enjoy the sunset. Your stay is arranged in a unique mud accommodation that will definitely gives you a out of this world experience, later in the evening the performance of the local artists and musicians will surely captivate you. Over night Sand Dunes/Desert Camp/Swiss tent.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='romanticday6'>DAY-06 : Khuri-Pokaran-Jodhpur
</h4>

    <p className='daycontent'>
    After breakfast in desert camp depart to Jodhpur,enroute visit Pokaran town,famous for beautifull fort.After reaching, check in the hotel. Head towards the guided tour of Jodhpur City. Mandore gardens, the former capital of Marwar, the view of the amazing memorial and the hall of local heroes of the wars is thoroughly captivating. Visit the grandeur of the Umaid Bhawan Palace, Mehrangarh fort and Jaswaqnt Thada.Evenings at leisure, walk along the flourishing spice market here, you are left fascinated by the aroma of the opulent variety of spices. Enjoy shopping at Jodhpur, go for famous tie and dye work, hand woven carpets, leather shoes with embroidery work, and puppets from the Girdikot and Sardar Market.Over night at the hotel.</p>
</div>
<br></br>
<div className='contentbox'>
<h4  className='romanticday7'>DAY-07 : Jodhpur - Vishnoi Village - Ranakpur </h4>

    <p className='daycontent'>
    After breakfast in the hotel proceed to Ranakpur,enroute visit famous village culture of the world"Vishnoi Village Culture".Upon arrival at Ranakpur first check in hotel,and proceed to visit best temple of rajasthan Chaumukha mandir(jain temple)it is dedicated to Bhagwan Adinath and was built in 1439.Then after visit Neminath,Parasnath,Sun temple and very nice and romantic sunset view on the beautifull lake.Overnight at the hotel.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='romanticday8'>DAY-08 :Ranakpur - Kumbhalgarh - Udaipur</h4>

<p className='daycontent'> After breakfast drive to Udaipur,on the way visit Kumbhal Garh,Kumbhal garh fort is famous for second biggest and third oldest wall of the world.Kunbhal garh fort is situated 1100m skyward in the Aravalli hills,built in 15 centuary,the fort walls stretch some 36 kilomiter and enclose around 360 temple,as well as palaces,gardens,baoris and 700 canon bunkers.Upon Arrival in udaipur,check in the hotel,then after proceed to see rajasthani culture and tredditional dance and puppet show at Bagore-ki-haveli.Over night at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='romanticday9'>DAY-09  : Udaipur Sightseeing</h4>

    <p className='daycontent'>
    After breakfast in the hotel your day trip start to the city includes a visit to city palace,the largest palace museum of rajasthan,300 years old Jagdish temple,Saheliyon-ki-bari,shilpgram,Sajjan Garh(monsoon palace).Take a walk through the colorful Bapu Bazaar and enjoy shopping the delicate silver Jewelry and tie and dye fabrics for which the city is famous. Towards sunset proceed to a cruise on Lake Pichola, and visit the Jag Mandir Palace amidst the lake, the picturesque beauty of the palace will take your breath away. Overnight at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4 className='romanticday10'>DAY-10 :Udaipur-Chittod Garh-Pushkar
</h4>

    <p className='daycontent'>
    After breakfast in the hotel,you will proceed to pushkar(secred city of god Brahma),enroute visit biggest fort of india,This fort is very old mentioned in Mahabharata(old epic of india belong to 1000 bc)) the name of Chittor,but the fort date start as a Chittod Garh from the 8th century, founded by King Bappa Rawal of Sisodia.Upon arrival in pushkar,first check in the hotel.Thenafter discover the treasure of holy town pushkar.Pushkar retains its sacredness, as Lord Brahma (the Creator) and Saraswati his wife fulfilled a ritual in the lake here. Pay your homage to the divine Brahma temple of the world and also socialize with the local life in the colorful bazaars. If it is the month of October/November then you are lucky to enjoy the world famous cattle fair of Pushkar. Towards the sunset visit the Pushkar Lake and 52 bathing "ghats" and numerous temples surrounding the lake, also enjoy the sunset view from the lake.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='romanticday11'>DAY-11 : Pushkar-Ajmer-Jaipur</h4>

    <p className='daycontent'>
    After breakfast drive to Jaipur(pink city),on the way visit sacred city Ajmer.After arrive in Jaipur,check in the hotel.Then after nice walk through the market and enjoy beautifull pink city.Overnight stay in the hotel.

</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='romanticday12'>DAY-12 :Jaipur Sightseeing </h4>

    <p className='daycontent'>
    After breakfast proceed to explore the pink city with your guide.Visit the impressive Amber Fort 12 km from Jaipur. At the foothill, mount on the elephant for the slow but steady climb up to the main gate. The ride will lead you to the bygone era of Rajaput Kings. After lunch drive to the Hawa Mahal, Jantar Mantar, City Palace and the in-house Museum. Walk along the bazaars of the "Pink City" and enjoy the colorful life of the city. You will be amazed at the sight of the men and the women folk dressed in the most colorful way, especially ladies adorned with heavy silver jewelry. Enjoy the typical Rajasthani Thali (food) with non-stop servings. Overnight at the hotel.

</p>
</div>
<br></br>

<div className='contentbox'>
<h4 className='romanticday13'>DAY-13 : Jaipur-Monkey Temple-Ranthambore</h4>

    <p className='daycontent'>
    After breakfast drive to ranthambore tiger park,enroute visit very famous Galta Jee,known by monkey temple.Upon arrival check in the hotel.Then after lunch drive to jungle of ranthambore in a jeep/canter for tiger tracking and birds watching.Overnight stay in hotel.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='romanticday14'>DAY-14 :Ranthambore-Bharatpur-Fatehpur Sikri-AgraGhost town-Agra</h4>

<p className='daycontent'>After breakfast drive to Agra,enroute visit Aabhaneri stepwell, Bharatpur wild life sanctuary and Fatehpur sikri (ghost town).After arrival in Agra check in the hotel,and overnight stay in hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='romanticday15'>DAY-15 : Agra-Mathura-delhi
</h4>

    <p className='daycontent'>
    Early in the morning 6 am enter in tajmahal and proceed to the taj mahal to see the architectural modern day wonder of the world, the incomparable Taj Mahal. This monument is a fine example of the fusion of many architectural styles is one of the wonders of the modern world. Built in 1652 by Emperor Shah Jahan. Agra the zenith of art and an enshrined romance. Taj Mahal a poem in white marble which took 22 years to build by 20,000 skilled artisans who inlaid the white marble edifice with precious and semi precious stones. Agra town lives mainly in the past. Once the seat of Mughal rulers. Their passion for building endowed it with some of the loveliest buildings in the world.After sight-seeing the Taj Mahal,very good and clean hotel for the sumptuous breakfast,the tour continues with a visit to the Agra Fort from where one can get the most moving view of the Taj from the little octagonal tower in the Agra Fort which lies across the river Jamuna. It was here that Aurangzeb the third son of Shah Jahan kept his father under house arrest, from where the Emperor spent the last years of his life gazing at the tomb of his beloved.Then after come back in hotel checkout the hotel and proceed to delhi,enroute visit sikandra(tomb of great Akbar),Mathura(birth place of hindu god Krishna).Upon arrival in delhi we will drop to you airport/hotel/ with sweet memories of ROMANTIC RAJASTHAN TOUR.</p>
</div>
<br></br>

</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Romanticrajasthan