import React from 'react'
import './Pakages.css'
import TAJMAHAL from '../rinkuuncle/img/TAJMAHAL.jpg'
import goldentriangle from '../rinkuuncle/img/goldentriangle.jpg'
import tiger from '../rinkuuncle/img/tiger.jpg'
import spritualtour from '../rinkuuncle/img/spritualtour.jpg'
import historicrajasthan from '../rinkuuncle/img/historicrajasthan.jpg'
import romanticrajasthan from '../rinkuuncle/img/romanticrajasthan.jpg'
import packageheading from '../rinkuuncle/img/packageheading.png'
import { Link } from 'react-router-dom'
const Pakages = () => {
  return (
    <div>
        <div className="container packages">
            <div className="row">
                <div className="col-md-3 ">
                    <Link to="/agra" className='packagelink'><div className='package'>
                        <img src={TAJMAHAL} className='packageimg'></img>
                        <h5 className='packagehead'>AGRA SAME DAY</h5>
                        <p>Discover the splendor of Agra in just one day with our expertly curated same-day tour.Marvel at the breathtaking Taj Mahal, explore the historic Agra Fort.</p>
                    </div>
                    </Link> 
                </div>

                <div className="col-md-3">
                    <Link to="/goldentriangle"className='packagelink'><div className='package'>
                        <img src={goldentriangle} className='packageimg'></img>
                        <h5 className='packagehead'>GOLDEN TRIANGLE</h5>
                        <p>Embark on the iconic Golden Triangle Tour and experience the essence of India through three of its most captivating cities: Delhi, Agra, and Jaipur.</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md-3">
                   <Link to="/goldentrianglewithtigersafari"className='packagelink'><div className='package'>
                        <img src={tiger} className='packageimg'></img>
                        <h5 className='packagehead'>GOLDEN TRIANGLE WITH TIGER SAFARI</h5>
                        <p>The perfect blend of India’s cultural heritage and wildlife adventure with our Golden Triangle Tour, complemented by an  tiger safari.</p>
                    </div>
                    </Link>
                </div>
                
            </div>
        </div>

        <div className="container packages">
            <div className="row">
                <div className="col-md-3 ">
                    <Link to="/spritualsameday"className='packagelink'><div className='package'>
                        <img src={spritualtour} className='packageimg'></img>
                        <h5 className='packagehead'>SPRITUAL SAME DAY</h5>
                        <p>Embark on a soul-enriching journey with our Spiritual Tour, crafted to guide you through the heart of spirituality.Engage in transformative activiti like guided meditation.</p>
                    </div>
                    </Link>
                </div>

                <div className="col-md-3">
                    <Link to="/ourpackages/rajasthanholiday"className='packagelink'> <div className='package'>
                        <img src={historicrajasthan} className='packageimg'></img>
                        <h5 className='packagehead'>HISTORIC RAJASTHAN</h5>
                        <p>Step back in time with our Historic Rajasthan Tour, where the grandeur of India's royal past comes alive.. Discover the captivating blend of regal history, and colorful traditions.</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md-3">
                    <Link to="/romanticrajasthan"className='packagelink'><div className='package'>
                        <img src={romanticrajasthan} className='packageimg'></img>
                        <h5 className='packagehead'>ROMANTIC RAJASTHAN</h5>
                        <p>Experience a fairy-tale romance with our Romantic Rajasthan Tour, designed to whisk you away to a land of timeless beauty and charm.</p>
                    </div>
                    </Link>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Pakages