import React from 'react'
import './Jaipurdaytrip.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import JAIPUR from '../rinkuuncle/img/JAIPUR.jpg'
import jaipur2 from '../rinkuuncle/img/jaipur2.jpg'
import jaipur3 from '../rinkuuncle/img/jaipur3.jpg'
import Enquiryform from '../components/Enquiryform'

const Jaipurdaytrip = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  className='jaipurpackageheading' >
          JAIPUR DAY TRIP
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span className='jaipurlocation'>JAIPUR</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizonatlline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/JAIPUR.jpg" class="d-block w-100" alt="..." height="450px" ></img>
  </div>
  <div className="carousel-item">
    <img src="/jaipur2.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/jaipur3.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 style={{ }}className='jaipurtouritinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
{/* <h4  className='jaipurdayone'>DAY-01 : Early Morning : 06:00 Am </h4> */}
{/* <div className='jaipurdayone'>DAY-01 : EARLY MORNING : 6:00AM</div> */}
<div className='Jaipurdayone'>
  DAY-01: EARLY MORNING : 6.00AM
</div>
    <p className='daycontent'>
    Leave Delhi and drive to Jaipur (268 kms / 5 hrs). Through the NH8
Sightseeing of the Pink City, Jaipur, is in store for this morning. The highlights of the day are City Palace, still used as residence of royal family in part, and its museum with a rich collection of paintings, artifacts and royal dresses; Jantar Mantar; Hawa Mahal and the bazaars of Jaipur. You can enjoy the evening at your leisure and rest or go for shopping. Savor traditional Rajasthani cuisine that includes Dal Bati, Churma and Bajare Ki roti served in typical Rajasthani style and buy handicrafts and tie and dye apparels as souvenirs. Overnight stay at hotel. After breakfast in the hotel, leave for Delhi. You will be served a farewell lunch at the hotel, which will be followed by transfer to the airport, where you can catch your flight to onward destination.
After visit, transfer to Hotel or similar and have a Buffet lunch in hotel restaurant.</p>
</div>
</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>
{/* <Outlet></Outlet> */}
</div>
  )
}

export default Jaipurdaytrip