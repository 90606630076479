// src/Gallery.js
import React,{useState} from 'react';
import './Gallery.css';
// import CloseIcon from '@mui/icons-material/Close';
import GALLERY from '../rinkuuncle/img/GALLERY.png'
import client1 from '../rinkuuncle/img/client1.jpeg'
import client2 from '../rinkuuncle/img/client2.jpeg'
import client3 from '../rinkuuncle/img/client3.jpeg'
import client4 from '../rinkuuncle/img/client4.jpeg'
import client5 from '../rinkuuncle/img/client5.jpeg'
import client6 from '../rinkuuncle/img/client6.jpeg'
import client7 from '../rinkuuncle/img/client7.jpeg'
import client8 from '../rinkuuncle/img/client8.jpeg'
import client9 from '../rinkuuncle/img/client9.jpeg'
import client10 from '../rinkuuncle/img/client10.jpeg'
import client11 from '../rinkuuncle/img/client11.jpeg'
import client12 from '../rinkuuncle/img/client12.jpeg'
import client13 from '../rinkuuncle/img/client13.jpeg'
import client14 from '../rinkuuncle/img/client14.jpeg'
import client15 from '../rinkuuncle/img/client15.jpeg'
import client16 from '../rinkuuncle/img/client16.jpeg'
import client17 from '../rinkuuncle/img/client17.jpeg'
import client18 from '../rinkuuncle/img/client18.jpeg'
import client23 from '../rinkuuncle/img/client23.jpeg'
import client19 from '../rinkuuncle/img/client19.jpeg'
import client20 from '../rinkuuncle/img/client20.jpeg'
import client21 from '../rinkuuncle/img/client21.jpeg'
import client22 from '../rinkuuncle/img/client22.jpeg'
import client24 from '../rinkuuncle/img/client24.jpeg'
import client25 from '../rinkuuncle/img/client25.jpeg'
import client26 from '../rinkuuncle/img/client26.jpeg'
import client27 from '../rinkuuncle/img/client27.jpeg'
import client28 from '../rinkuuncle/img/client28.jpeg'
import client29 from '../rinkuuncle/img/client29.jpeg'
import client30 from '../rinkuuncle/img/client30.jpeg'
import Popularcard from '../components/Popularcard';
import Footer from '../components/Footer';


const Gallery = () => {
    const images = [
       
       {
        id:1,
        img:client1,
       } ,
       {
        id:2,
        img:client2,
       } ,
       {
        id:3,
        img:client3,
       } ,
       {
        id:4,
        img:client4,
       } ,
       {
        id:5,
        img:client5,
       } ,
       {
        id:6,
        img:client6,
       } ,
       {
        id:7,
        img:client7,
       } ,
       {
        id:8,
        img:client8,
       } ,
       {
        id:9,
        img:client9,
       } ,
       {
        id:10,
        img:client10,
       } ,
       {
        id:11,
        img:client11,
       } ,
       {
        id:12,
        img:client12,
       } ,
       {
        id:13,
        img:client13,
       } ,
       {
        id:14,
        img:client14,
       } ,
       {
        id:15,
        img:client15,
       } ,
       {
        id:16,
        img:client16,
       } ,
       {
        id:17,
        img:client17,
       } ,
       {
        id:18,
        img:client18,
       } ,
       {
        id:19,
        img:client19,
       } ,
       {
        id:20,
        img:client20,
       } ,
       {
        id:21,
        img:client21,
       } ,
       {
        id:22,
        img:client22,
       } ,
       {
        id:23,
        img:client23,
       } ,
       {
        id:24,
        img:client24,
       } ,
       {
        id:25,
        img:client25,
       } ,
       {
        id:26,
        img:client26,
       } ,
       {
        id:27,
        img:client27,
       } ,
       {
        id:28,
        img:client28,
       } ,
       {
        id:29,
        img:client29,
       } ,
       {
        id:30,
        img:client30,
       }  
    ]

    const [data,setdata]=useState(false)
    const [temping,settemping]=useState('')
    const getimage=(img)=>
    {
       settemping(img);
       setdata(true);
    }

    const imgaction=(action)=>
    {
        let i=data.index;
        if(action==='nextimg')
        {
            setdata({src:images[i+1],i:i+1})
        }
    }
    return (
        
        <div>
            <div>
                <img src={GALLERY} style={{width:"100%", height:"400px"}}></img>
            </div>
          
            <div className={data ? "data open" : "data"} >
                <img src={temping}></img>
                <button style={{position:"absolute" , top:"80px", right:"40px", height:"50px" , width:"40px", backgroundColor:"black", color:"white", border:"2px solid white"}} onClick={()=>setdata(false)}>X</button>
            </div>

            <div className="gallery">
            {images.map((src, index) => (
                <div className="pics" key={index}>
                    
                    <img src={src.img } alt={`Gallery Item ${index + 1}`} onClick={()=> getimage(src.img)} />
                  
                </div>
            ))}
        </div>

        <div>
            <Popularcard></Popularcard>
        </div>
        <div>
            <Footer></Footer>
        </div>
        </div>
       
    );
};

export default Gallery;
