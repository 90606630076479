import React from 'react'
import './Spritualtour.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import varanasi1 from '../rinkuuncle/img/varanasi1.jpg'
import haridwar1 from '../rinkuuncle/img/haridwar1.jpg'
import varanasi2 from '../rinkuuncle/img/varanasi2.jpg'
import location from '../rinkuuncle/img/location.png';
import Enquiryform from '../components/Enquiryform'

const Spritualtour = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2   className='spritualpackageheading' >
    Spritual Tour (7 Nights/ 8 Days)
    </h2>

    <p >
        <span ><img src={location} alt=""  style={{  height: "22px",}}></img></span> 

        <span className='sprituallocation'>Delhi » Rishikesh » Haridwar » Varanasi</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div  className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src={varanasi1} class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={haridwar1} class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={varanasi2} class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 style={{ }}className='spritualitinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4   className='spritualday1'>DAY-01 : Arrival at Delhi</h4>

    <p style={{width:"98%", paddingLeft:"10px"}}>
    A warm traditional welcome at Airport awaits you at Delhi followed by transfer to your hotel for dinner and overnight stay. You will also receive the full details of the tour itinerary once again at dinner.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='spritualday2'>DAY-02 : Delhi TO Rishikesh</h4>

    <p style={{width:"98%", paddingLeft:"10px"}}>
    In Sanskrit Rishikesh is a name of Vishnu that means 'lord of the senses'. The place gets its name after Lord Vishnu who appeared to 'Raibhya Rishi', as a result of his tapasya (austerities), as Lord Hrishikesh.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='spritualday3'>DAY-03 : Rishikesh</h4>

    <p className='daycontent'> 
    Sightseen of Parmarth Niketan Ashram , Lakshman Jhula , Ram Jhula and Ganga River Shiromani or Yoga Classes.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='spritualday4'>DAY-04 :Rishikesh To Haridwar
</h4>

    <p className='daycontent'>
    Haridwar is also spelled Hardwar, is an ancient city and municipality in the Haridwar district of Uttarakhand, India. According to popular Hindu legend, it was here that Goddess Ganga when Lord Shiva released the mighty river from the locks of his hair.
    After breakfast, sightseen of Chandi Devi Temple and Ganga Arti Har ki Pauri .</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='spritualday5'>DAY-05 : Haridwar</h4>

    <p className='daycontent'>
    Sightseeing of Mata Mansa Devi Mandir , City Walk Tour and Bharat Mata Temple.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='spritualday6'>DAY-06 : Haridwar To Varanasi</h4>

    <p className='daycontent'>
    Varanasi is a city in the northern Indian state of Uttar Pradesh dating to the 11th century B.C. Regarded as the spiritual capital of India, the city draws Hindu pilgrims who bathe in the Ganges River’s sacred waters and perform funeral rites. After Reaching Varanasi Check in hotel and full day rest.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='spritualday7'>DAY-07 : Varanasi</h4>

    <p className='daycontent'>
    Early Morning going to boat Ride and after lunch we will go for sun sightseen at Sarnath.</p>
</div>
<br></br>

<div className='contentbox'>
<h4   className='spritualday8'>DAY-08 : Varanasi</h4>

    <p className='daycontent'>
    After breakfast we will visit all ghats by walk or by boat and at evening we will visit Dashashwamedh Ghat(For Aarti). Next day drop at bus station or Airport.</p>
</div>
</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Spritualtour