import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from "react-icons/gi";
import './Navbar.css';
import carlogo from '../rinkuuncle/img/carlogo.png'

const Navbar = () => {
    const[showmenu , setshowmenu]=useState(false);
    
    const buttonhandle=()=>
    {
        setshowmenu(!showmenu);
    }
  return (
    <div className="nav_div">
        
    <div className="outer-div">
        <div >
           <a href="/"><img src={carlogo} className="logo"></img></a>
        </div>
        
        <nav className={showmenu ? "menu-mobile" : "menu-web"}>

            <ul>
                <li><a href="/">HOME</a></li>
                <li><a href="/ourpackages">PAKAGES</a>
                    <div className="dropdown" >
                        <ul>
                            <Link to="/agra"><li>AGRA SAME DAY</li></Link>
                            <Link to="/spritualsameday"><li>SPRITUAL DAY TRIP</li></Link>
                            <Link to="/ourpackages/jaipurdaytrip"><li>JAIPUR DAY TRIP</li></Link>
                            <Link to="/goldentriangle"><li>GOLDEN TRIANGLE TOUR</li></Link>
                            <Link to="/goldentrianglewithtigersafari"><li>GOLDEN TRIANGLE WITH JUNGLE SAFARI</li></Link>
                            <Link to="/ourpackages/goldentrianglewithpushkar"><li>GOLDEN TRIANGLE WITH PUSKAR</li></Link>
                            <Link to="/spritualtour"><li>SPRITUAL TOUR</li></Link>
                            <Link to="/ourpackages/junglesafari"><li>JUNGLE SAFARI</li></Link>
                            <Link to="/ourpackages/royalgujrattour"><li>ROYAL GUJRAT TOUR</li></Link>
                            <Link to="/ourpackages/rajasthanculturetour"><li>RAJASTHAN CULTURE TOUR</li></Link>
                            <Link to="/ourpackages/rajasthanpalacetour"><li>RAJASTHAN PALACE TOUR</li></Link>
                            <Link to="/ourpackages/rajasthancompeletetour"><li>RAJASTHAN COMPLETE TOUR</li></Link>
                            <Link to="/romanticrajasthan"><li>RAJASTHAN ROMANTIC TOUR</li></Link>
                            <Link to="/ourpackages/rajasthanholiday"><li>RAJASTHAN HOLIDAY</li></Link>
                        </ul>
                    </div>
                    </li>

                    <li><a href="/gallery">GALLERY</a></li>
                    <li><a href="/aboutus">ABOUT US</a></li>
                    <li><a href="/contactus">CONTACT US</a></li>
                   
            </ul>
        </nav>

        <div className='ham-menu'>
            <button onClick={buttonhandle} className='menubtn'>
            <GiHamburgerMenu />
            </button>
        </div>
    </div>
</div>

  )
  
}

export default Navbar