import React from 'react'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import udaipur from '../rinkuuncle/img/udaipur.jpg'
import JAIPUR from '../rinkuuncle/img/JAIPUR.jpg'
import pushkar2 from '../rinkuuncle/img/pushkar2.jpg'
import location from '../rinkuuncle/img/location.png';
import Enquiryform from '../components/Enquiryform'
import './Goldentrianglewithpuskar.css'

const Goldentrianglewithpuskar = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div style={{ borderTop: "1px solid blue",marginBottom: "-20px" , marginTop:"30px"}}></div>

   {/* <!-- [package heading] --> */}
    <h2   className='gtpuskarpackageheading' >
    Golden Triangle with Pushkar (6 Nights/ 7 Days)
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span style={{ }}className='gtpuskarloaction'>Delhi » Agra » Jaipur » Pushkar » Udaipur</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/pushkar2.jpg" class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/udaipur.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/JAIPUR.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 className='touritineraryhead'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4  className='gtpdayone'>DAY-01 :Arrival at Delhi </h4>

    <p className='daycontent'>
    A warm traditional welcome at Airport awaits you at Delhi followed by transfer to your hotel for dinner and overnight stay. You will also receive the full details of the tour itinerary once again at dinner.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='gtpdaytwo'>DAY-02 :DELHI</h4>

<p className='daycontent'>After delicious breakfast, you will go for a guided sightseeing tour of Delhi, the capital of India, in an your choice of vehicles. The first half concentrates on the highlights of New Delhi such as driving past the daunting Bristish era monuments such as Rashtrapati Bhawan (President's Palace), Parliament House and India Gate, built in the memory of Indian martyrs. You can enjoy a boat ride and the well-maintained lawns of India Gate. Then, there are relics from the past such as Humayun's tomb and Qutab Minar, for you to explore. After lunch, you will once again go for sightseeing to Old Delhi, where you will visit the sprawling stronghold of Mughal Era, the Red Fort, which has played an important role in Indian history and is pride of India. Then, there is Jama Masjid, India's largest mosque and the Raj Ghat, memorial to Mahatma Gandhi, Father of India. Ride in a rickshaw in the narrow and crowded streets of Chandni Chowk Bazaar, where you can shop as much as you like for all kinds of stuffs including clothes, artifacts, jewellery and handicrafts. It is Asia's largest market for electrical goods and its delicacies such as Kachori, Paranthas and Kulfi are quite famous. In the evening, you will witness the sound and light show at Red Fort showing Indian history during Mughal era as its main theme. Overnight stay at hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gtpdaythree'>DAY-03 : AGRA </h4>

    <p className='daycontent'>
    After breakfast at hotel, a guided tour of Agra is in store for this day. You will visit Taj Mahal, one of the wonders of the world and considered an emblem of love; Agra Fort built in red sandstone with its magnificent palaces, mosques and lawns; and Sikandra, the tomb of Emperor Akbar. In the evening, you will check in at the hole in Agra and then spend it at your leisure. You may either rest or explore the markets of Agra for souvenirs such as miniature Taj in marble, marble tabletops and jewellery boxes set with precious and semi-precious stones, leather shoes and purses, brass handicrafts and Petha (the famous sweet of Agra). Overnight stay at hotel.
</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gtpdayfour'>DAY-04  : AGRA TO JAIPUR</h4>

    <p className='daycontent'>
    In the morning, you will leave for Jaipur visiting Fatehpur Sikri enroute. Fatehpur Sikri is 40 km from Agra, which was built by Emperor Akbar to serve as his capital in the honor of Sheikh Salim Chisti, who had blessed him with an heir and a son. But the city was later deserted due to shortage of water. The highlights of the city are Panch Mahal, Anup Talao, Diwan-e-Khas, sacred Tomb of Chisti and Buland Darwaza along with many other sub-monuments. In the afternoon, driving to Jaipur, the capital of Rajasthan. Take a look at the real life of Rajasthan as you move through the city with men and women dressed in colorful dresses. Whirlpool skirts and silver jewellery of women and turbans of men present a wonderful sight. Check in at the hotel. After freshening up, you will enjoy the royal ride to Amber Fort on an elephant. The other highlights of the day's visit are Hall of Victory and Jalmahal, the beautiful palace with mirror work situated amidst the Man Sagar lake. Overnight stay at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gtpdayfive'>DAY-05 : JAIPUR </h4>

    <p className='daycontent'>
    Sightseeing of the Pink City, Jaipur, is in store for this morning. The highlights of the day are City Palace, still used as residence of royal family in part, and its museum with a rich collection of paintings, artifacts and royal dresses; Jantar Mantar; Hawa Mahal and the bazaars of Jaipur. You can enjoy the evening at your leisure and rest or go for shopping. Savor traditional Rajasthani cuisine that includes Dal Bati, Churma and Bajare Ki roti served in typical Rajasthani style and buy handicrafts and tie and dye apparels as souvenirs. Overnight stay at hotel. After breakfast in the hotel, leave for Delhi. You will be served a farewell lunch at the hotel, which will be followed by transfer to the airport, where you can catch your flight to onward destination.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gtpdaysix'>DAY-06 : Jaipur To Pushkar </h4>

    <p className='daycontent'>
    Pushkar is one of the most visited destinations for pilgrimages and cultural vacations in India, attracting thousands of visitors annually from India and abroad. Situated 14 km from Ajmer, Pushkar is one of the five sacred Dhams, and lies on the shore of the holy Pushkar Lake. Pushkar has five main temples, along with many smaller temples and 52 ghats, where pilgrims can bathe in the sacred waters. Tours to Pushkar include visits to these, along with other nearby attractions. The fairs of Pushkar are also famous, and the best known is the Pushkar Camel Fair. Pushkar offers the best travel packages and travel deals, along with cheap holiday packages.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='gtpdayseven'>DAY-07 : Pushkar To Udaipur or Delhi </h4>

    <p className='daycontent'>
    Udaipur is also known as the "City of Lakes" is a city, Nagar Nigam and the administrative headquarters of the Udaipur district in the Indian state of Rajasthan.</p>
</div>



</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Goldentrianglewithpuskar