import React from 'react'
import './Services.css'
import car from '../rinkuuncle/img/car.png'
import flight from '../rinkuuncle/img/flight.png'
import hotel from '../rinkuuncle/img/hotel.png'
import railway from '../rinkuuncle/img/railway.png'
import SERVICES from '../rinkuuncle/img/SERVICES.png'

const Services = () => {
  return (
    <div className='servicesmain'>
        <div>
            <img src={SERVICES} className='servicesimg'></img>
        </div>

        <div className='container-fluid'>
           <div className="row">
                <div className="col-md-3">
                    <div>
                        <img src={hotel} className='hotel'></img>
                        <h5 className='hotelcontent'>HOTEL BOOKING</h5>
                    </div>
                </div>
                <div className="col-md-3">
                <div>
                        <img src={flight} className='flight'></img>
                        <h5 className='flightcontent'>DOMESTIC FLIGHT BOOKING</h5>
                    </div>
                </div>
                <div className="col-md-3">
                <div>
                        <img src={car} className='car'></img>
                        <h5 className='carcontent'>CAR RENTAL</h5>
                    </div>
                </div>
                <div className="col-md-3">
                <div>
                        <img src={railway} className='railway'></img>
                        <h5 className='railwaycontent'>DOMESTIC RAILWAY BOOKING</h5>
                    </div>
                </div>
           </div>
        </div>
               
      

    </div>
  )
}

export default Services;