import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
  return (
    <div className='main'>

        <div className="container-fluid footer">
            <div className="row">

                <div className="col-md-4">
                    <div className='first_row'>
                        <h5>OFFICE</h5>
                        <p>426 BHAI PARMANAND COLONY <br></br>DELHI-110009</p>
                        <p>24/7 FACILITY AVAILABLE </p>
                        <p>CALL - +91-9711443099 <br></br> +91-9811739850 </p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className='second_row'>
                        <h5>LINKS</h5>
                        <ul>
                            <Link to="/gallery" className='list'><li>gallery</li></Link>
                            <Link to="/privacypolicy" className='list'><li>privacy policy</li></Link>
                        </ul>
                        <Link to="" className='email'><p className='emailcontent'>EMAIL - Rainbowtravelindia@gmail.com</p></Link>
                    </div>
                </div>

                <div className="col-md-4">
                     <div className='third_row'>
                        <h5>CHECK OUR REVIEWS</h5>
                        <div className='reviewimg'>
                        <a href="https://www.tripadvisor.in/Attraction_Review-g304551-d19643477-Reviews-Discover_India_on_wheels_Rinku_Driver_Service-New_Delhi_National_Capital_Territo.html"><img src="/tripadvisor.png" className='tripadvisorimg'></img></a>
                        <a href="https://g.co/kgs/B2tK5XR"><img src="/googlereview.png" className='googleimg'></img></a>
                    
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer