import React from 'react'
import jaipur2 from '../rinkuuncle/img/jaipur2.jpg'
import gujrat from '../rinkuuncle/img/gujrat.jfif'

import rajasthanpalace from '../rinkuuncle/img/rajasthanpalace.jpg'
import './Popularcard.css'
import { Link } from 'react-router-dom'

const Popularcard = () => {
  return (
    <div >
        <div className="popular_head" >
            you might also like
        </div>
        
         <div className="container packages">
            <div className="row">
                <div className="col-md-3 ">
                    <Link to="/ourpackages/royalgujrattour"className='packagelink'><div className='package'>
                        <img src="/gujrat2.jpg" className='packageimg'></img>
                        <h5 className='packagehead'>ROYAL GUJRAT TOUR</h5>
                        <p>Experience the royal heritage and diverse culture of Gujarat, from majestic palaces to vibrant markets.</p>
                    </div>
                    </Link>
                </div>

                <div className="col-md-3">
                    <Link to="/ourpackages/rajasthanpalacetour"className='packagelink'> <div className='package'>
                        <img src="/rajasthanpalace.jpg" className='packageimg'></img>
                        <h5 className='packagehead'>RAJASTHAN PALACE TOUR</h5>
                        <p>Explore the opulent palaces and forts of Rajasthan, each telling stories of a glorious past.</p>
                    </div>
                    </Link>
                </div>
                
                <div className="col-md-3 ">
                    <Link to="/ourpackages/jaipurdaytrip" className='packagelink'><div className='package'>
                        <img src="/jaipur2.jpg" className='packageimg'></img>
                        <h5 className='packagehead'>JAIPUR DAY TRIP</h5>
                        <p>Explore the stunning architecture and vibrant culture of Jaipur in a single unforgettable day.</p>
                    </div>
                    </Link> 
                </div>
                
                
            </div>
        </div>


    </div>
  )
}

export default Popularcard;