import React from 'react'
import './Spritualsameday.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import haridwar1 from '../rinkuuncle/img/haridwar1.jpg'
import haridwar2 from '../rinkuuncle/img/haridwar2.jpg'
import haridwar3 from '../rinkuuncle/img/haridwar3.jpg'
import location from '../rinkuuncle/img/location.png';
import Enquiryform from '../components/Enquiryform'
const Spritualsameday = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  style={{}} className='spritualsamepackageheading' >
          SPRITUAL DAY TRIP
    </h2>

    <p >
        <span ><img src={location} alt=""  style={{  height: "22px",}}></img></span> 

        <span  className='spritualsamelocation'>HARIDWAR</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src={haridwar1} class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={haridwar2} class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={haridwar3} class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3  className='spritualsameitinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4  className='spritualsameday1'>DAY-01 : Arrive HARIDWAR</h4>

    <p className='daycontent'>
    Leave Delhi and drive to Haridwar (213 kms / 4 h 40 min). Through the NH58 . Among all the mythological sites in Haridwar, the temple of Chandi Devi holds much significance. The temple occupies a position on the top of NEEL PARVAT. Constructed in the year 1929 by the KING OF KASHMIR SUCHAT SINGH, the temple is dedicated to GODDESS CHANDI DEVI, after whom it is named. River Ganges flowing just by the site adds much to the overall appearance of Chandi Devi. The temple has the privilege of being counted among the popular SHAKTIPEETHAS (Hindu sites of special mythological significance) in the Northern India. Due to the belief that whoever pays a visit to the temple gets his/ her wishes fulfilled by the blessings of Goddess Chandi Devi, the temple is considered as Siddhapitha. This belief makes the temple of Chandi Devi all the more popular among tourists, both from India and abroad.
    The Temple is located at a distance of 4 kilometres (2.5 mi) from Har ki Pauri. To reach the temple one has to either follow the three kilometre trekking route from Chandighat and reach the shrine by climbing a number of steps or climb the recently introduced rope-way (cable car) service. The rope-way service known as Chandi Devi Udankhatola was introduced for the benefit of the pilgrims and it caters to the pilgrims also to the nearby located Mansa Devi shrine. The rope-way carries the pilgrims from the lower station located near Gauri Shankar Temple on the Nazibabad Road directly to the Chandi Devi Temple located at an altitude of 2,900 metres (9,500 ft). The total length of the ropeway route is about 740 metres (2,430 ft) and height is 208 metres (682 ft). There is a dense forest on the other side of the hill and the ropeway offers scenic views of the Ganges River and Haridwar. King Vikramaditya is said to have built it in 1st century BC in the memory of his brother, Bharthari who had come to meditate here on the bank of the Ganges. An area within Har Ki Pauri, where the evening Ganga Aarti takes places and which is considered most sacred is known as Brahmakund (Devanagari: ब्रह्म कुण्ड). It is considered to be the spot where the drops of Amrit fell over from the sky, while being carried in a pitcher by the celestial bird, Garuda after the Samudra Manthan. Every day, Har Ki Pauri ghat witnesses hundreds taking a dip in water of the Ganges. The place is considered very auspicious. Over the years the ghats have undergone major extension and renovation as the crowds increased in subsequent Kumbh Melas. Several temples have come up on the steps, most built in late 19th century.</p>
</div>
</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
    
  )
}

export default Spritualsameday