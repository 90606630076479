import React from 'react'
import { Link } from 'react-router-dom'
import call from '../rinkuuncle/img/call.png'
import insta from '../rinkuuncle/img/insta.png'
import facebook from '../rinkuuncle/img/facebook.png'
import './Header.css'

const Header = () => {
  
  return (
    <div>
        <div className='header'>
            <a href='https://www.facebook.com/rinku.sharma.92351?mibextid=ZbWKwL'><img src={facebook} className='fb'></img></a>
            <a href='https://www.instagram.com/rinkusharma36/profilecard/?igsh=MWY0MGF4NWlydml0Zw=='><img src={insta} className='insta'></img></a>
            <img src={call} className='call'></img>
            <span className='number'>
              +91-9711443099
            </span>
            
        </div>
    </div>
  )
}

export default Header