import React from 'react'
import Enquiryform from '../components/Enquiryform'
import worldtour from '../rinkuuncle/img/worldtour.png'
import './Contactsection.css'

const Contactsection = () => {
  return (
    <div>
        <div className="container-fluid mainbg">
          <div className="row">
            <div className="col-md-8 contactleft">
                <img src={worldtour} className='worldtour'></img>
            </div>
            <div className="col-md-4 contactright">
              <Enquiryform></Enquiryform>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Contactsection