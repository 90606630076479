import React from 'react'
import './Rajasthanpalacetour.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import Enquiryform from '../components/Enquiryform'

const Rajasthanpalacetour = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  className='rajpalacepackageheading' >
    Rajasthan Palace Tour (17 Nights/ 18Days)
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span className='rajpalacelocation'> Delhi » Jaipur » Samode » Bikaner » Jaisalmer » Jodhpur » Kumbalgarh » Udaipur » Bijaipur » Bundi » Ranthambhore » Agra » Delhi</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/palace.jpg" class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/palace2.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/udaipur.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3  className='rajpalaceitinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4 className='rajpalaceday1'>DAY-01 : Arrive Delhi </h4>

    <p className='daycontent'>
    Arrival at Delhi, our representative will meet you at arrival hall of IGI airport and assist you in check-in Hotel. Overnight stay at Delhi.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday2'>DAY-02 :Delhi To Jaipur</h4>

<p className='daycontent'>In the morning, We drive to Jaipur also know as Pink City. The city was founded in 1728 by Maharaja Sawai Jai Singh II, the ruler of Amber. It remains the only city in the world symbolizing the nine divisions of the universe, through nine rectangular sectors. Upon arrival check into the hotel. Rest of the day free for independent activities.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday3'>DAY-03 :Jaipur</h4>

    <p className='daycontent'>
  
In the morning, drive to visit Amber fort and take an elephant ride to reach the fort situated on the hill. After Amber fort visit, you will be taken to visit city palace, Hawa mahal (palaces of winds), Jantar Mantar and other main sites of Jaipur. Evening free for independent activities.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday4'>DAY-04 :Jaipur – Samode </h4>

    <p className='daycontent'>
   
Morning drive to Samode, reach Samode and check into the hotel. Rest of the day is free for independent activities. Overnight stay at Samode Palace.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday5'>DAY-05 : Samode – Bikaner
</h4>

    <p className='daycontent'>
    
Proceed to princely state of Bikaner, founded by Rao Bikaji (1465-1504 AD) in the year 1488 AD. Arrive Bikaner and transfer to the hotel. Overnight stay in Bikaner.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday6'>DAY-06 : Bikaner – Jaisalmer
</h4>

    <p className='daycontent'>
    Morning visit Junagarh fort and famous Deshnoke temple (Rat temple). Later drive to Jaisalmer also know as Golden city. Upon arrival check into the hotel. Overnight in Jaisalmer.</p>
</div>
<br></br>
<div className='contentbox'>
<h4  className='rajpalaceday7'>DAY-07 :Jaisalmer Sightseeing </h4>

    <p className='daycontent'>
    After breakfast, we will proceed for the sightseeing of Golden city. We visit old town of Jaisalmer & visit Jaisalmer Fort, built in the year 1156 by Rawal Jaisal. After visit to Jaislamer fort, visit Patwon ki haveli and enjoy camel ride at sand dunes in the evening</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday8'>DAY-08 :Jaisalmer – Jodhpur  </h4>

<p className='daycontent'>
Proceed to Jodhpur in the morning, bustling desert city is the second largest city in Rajasthan. Rao Jodha founded the city in 1459 and its named after him. It was also know an Marwar. Reach Jodhpur and stay night in the hotel</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday9'>DAY-09  :Jodhpur – Kumbalgarh via Ranakpur</h4>

    <p className='daycontent'>
    
Morning visit Mehrangarh fort (massive fort) built by Rao Jodha, this 5km long Fort is on a 125m high hill & has some magnificent palaces with marvellously carved panels latticed window. After visit to Mehrangarh fort, visit the museum and Jaswat thada. After Jodhpur sightseeing, continue drive to Kumbalgarh en route visit Ranakpur Jain temple, later drive to Kumbalgarh. Kumbalgarh is quite unique and interesting destination of Rajasthan. Stay overnight at hotel The Aodhi</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday10'>DAY-10 : Kumbalgarh – Udaipur
</h4>

    <p className='daycontent'>
   
In the morning, visit Kumbalgarh fort and then drive to Udaipur was founded by Maharana Udai Singh and is set on the edges of three lakes and also known as City of Lakes. Overnight stay in Udaipur.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday11'>DAY-11 : Udiapur Sightseeing </h4>

    <p className='daycontent'>
    Morning proceed for the sightseeing of Udaipur. Visit city palace, sahelion ki bari and Jagdish temple. Enjoy boat ride on lake pichola, the beauty of the lake is enhanced by the surrounding hills, palaces, temples, bathing ghats & embankments. The Jag Niwas (Lake Palace) & Jag Mandir, the two island palaces on the lake look like pearls in the lake. Overnight stay in Udaipur.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday12'>DAY-12 :Udaipur – Bijaipur via Chittorgarh </h4>

    <p className='daycontent'>
    Drive to Bijaipur en route visit Chittorgarh fort. Chittoragrh fort is the finest examples of Rajput architecture and the influence of Mughal style on them. Continue drive to Bijaipur, Bijaipur is surrounded by the Aravalli hills on the three sides and is circumscribed by a massive wall with four gateways. Overnight stay in Bijaipur.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday13'>DAY-13 :Bijaipur To Bundi </h4>

    <p className='daycontent'>
    Morning proceed for the sightseeing of Bundi. Taragarh fort is one of the places to see here, and like almost every Rajasthani town, Bundi also has a lake. Rajput heritage continues in Bundi, famous even today for paintings depicting royal hunts, murals on the walls of the palace and its lacquer work on toys and ornaments. In fact, the Chitrashala or Hall of Paintings has one of the best examples seen in Rajasthan. Bundi is also aknown as city of wells for its more the 50 wells. Overnight stay in Bundi.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday14'>DAY-14 :Bundi – Ranthambhore</h4>

<p className='daycontent'>
Morning drive to Sawai Madhopur (Ranthambhore National Park), Ranthambhore national park is located in Sawai Madhopur district of Rajasthan. It is located at junction of aravalli and vindhya hill range. Ranthambhore national park is the finest place for the tiger sighting. It was declared as project tiger reserves in the year 1973 and it became national park in year 1980. Overnight in Ranthambhore</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday15'>DAY-15 : Ranthambhore</h4>

    <p className='daycontent'>
    
Enjoy morning and afternoon game rides by jeep or canter at Ranthambhore national park</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday16'>DAY-16 :Ranthambhore – Agra</h4>

    <p className='daycontent'>
    After breakfast, we will drive to Agra in route visit Aabhneri Stepwell, Fatehpur Sikri. Fatehpur Sikri is 40 kms from Agra and built by Emperor Akbar in 1569 and abandoned after 15 years due to scarcity of water. Continue drive to Agra. Overnight stay in Agra</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajpalaceday17'>DAY-17 :Agra
</h4>

    <p className='daycontent'>
    Sightseeing In the morning, we visit Taj Mahal at sunrise, symbol of eternal love, which stands on the banks of River Yamuna. It was built by the 5th Mughals Emperor, Shah Jahan in the year 1631 in memory of his wife, Mumtaz Mahal. It was completed in 22 years. After Taj Mahal visit, we proceed for Agra Fort, After Agra sightseeing.Overnight stay in agra hotel</p>
</div>
<br></br>

<div className='contentbox'>
<h4 className='rajpalaceday18'>DAY-18 :Agra To Delhi

</h4>

    <p className='daycontent'>
    Proceed for Delhi sightseeing, your tour includes visit to Qutab Minar, Humayu Tomb, India Gate, President House, Parliament, Red Fort, and Jama Masjid. In the evening you will roaming through the shopping streets in Cannaught place. According to the flight departure timings, transfer to Delhi international airport to board the flight for onward journey</p>
</div>
<br></br>
</div>
<div className="col-md-4 ">
<Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    
</div>
<Popularcard></Popularcard>
<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Rajasthanpalacetour