import React from 'react'
import './Rajasthanculturetour.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import Enquiryform from '../components/Enquiryform'

const Rajasthanculturetour = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  className='rajculturepackageheading' >
    Rajsthan Culture Tour (19 Nights/ 20 Days)
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span  className='rajculturelocation'> Delhi » Mandawa » Bikaner » Khimsar » Jaisalmer » Jodhpur » Luni » Kumbalgarh » Udaipur » Deogarh » Rupangarh » Jaipur » Karauli » Bharatpur » Agra » Delhi</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/desert.jpg" class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/desert2.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/TAJMAHAL.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 className='rajculturetouritinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4   className='rajcultureday1'>DAY-01 : Arrive Delhi </h4>

    <p className='daycontent'>
    Meet and assist by our representative upon your arrival at the airport. Then drive to your hotel with your driver and car. After checkout to hotel, city tour of this imperial British capital of the 20th century introduces you to the Qutub Minar – the tallest free standing minaret in stone in the world; then onto Humayun's tomb – the first Mughal construction in India from 1565 AD. Also see the Rashtrapati Bhawan, the India Gate built in the earlier half of the 20th century. Drive through the old city past the Red Fort and visit the Jama Masjid – the biggest of its kind in India. Here take a rickshaw through the winding streets of the old city to see the life of the people in this part of the world. A perfect introduction of your tour. The rickshaw puller pulls you through the imperial avenue the Chandni Chowk where you will find each and every small business under the sky. Finally, reach Rajghat, the memorial of Mahatma Gandhi, where your chauffeur is waiting for you to take you to your next stop the Birla Mandir, a Hindu temple in activity.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajcultureday2'>DAY-02 :Delhi – Mandawa (260km)</h4>

<p className='daycontent'>In the morning, continue on your route to Mandawa, see this beautiful village of Shekhawati that used to be an important caravanserai of the yesteryears. See the many havelis of this quaint little town built by the rich and the famous merchants of the 18th and 19th centuries. These havelis have been painted with activities of daily life and of deities to break the monotony of the desert life.Then proceed towards Bikaner en route stop at Fatehpur to see the splendid havelis of the local merchants of Shekhawati. Upon arrival check into your hotel for the night.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday3'>DAY-03 : Mandawa – Bikaner – Gajner (180km)</h4>

    <p className='daycontent'>
    In the morning see this beautiful village of Shekhawati that used to be an important caravanserai of the yesteryears. See the many havelis of this quaint little town built by the rich and the famous merchants of the 18th and 19th centuries. These havelis have been painted with activities of daily life and of deities to break the monotony of the desert life.Then proceed towards Bikaner en route stop at Fatehpur to see the splendid havelis of the local merchants of Shekhawati. Reach Bikaner by lunch time. See the Junagarh Fort, a royal museum that encompasses history of Bikaner from its days of construction that dates back to the 16th century. Later visit the camel breeding farm, a unique experience. In the evening walk along the busy market area of Bikaner city centre before proceeding towards Gajner – the erstwhile hunting grounds and leisure palace of the kings of Bikaner.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday4'>DAY-04 :Bikaner – Nagaur – Khimsar (150km) </h4>

    <p className='daycontent'>
    After breakfast we set towards our journey across the Thar Desert to reach Khimsar. En route visiting the world famous rat temple at Deshnoke. Reach Nagaur by lunch-time and visit the 15th century fortress where the Maharaja Ajay Singh (Naga Dynasty) used to rule. See the Hawa Mahal and the other royal palaces of this otherwise deserted fortress.Proceed towards Khimsar for the night.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday5'>DAY-05 : 
Khimsar – Osyan – Pokaran – Jaisalmer (230km)
</h4>

    <p className='daycontent'>
    After breakfast leave the Khimasar castle and reach Osyan (55km) and see the temple complex of Shakti (the Mother Goddess) and the Jain temples in the vicinity. Also see the temple of Harihar (Shiva and Vishnu together). Proceed towards the desert kingdom of Pokaran where the 3rd Mughal emperor, Akbar the Great, was born in 1543 inside the fort. The bazaar in the middle of this village is very colourful and full of local flavours. After lunch continue towards Jaisalmer and arrive by early evening to witness the sunset over the desert with the most imposing edifice of the town behind you.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday6'>DAY-06 : Jaisalmer
</h4>

    <p className='daycontent'>
    Morning an exclusive visit of the Jain temples inside the Sonar Kila.. The Jains are a community that respects life and are pure vegetarians by religion. Visit the citadel's palace that houses a museum with a huge collection of robes and artifacts from the royal family of Jaisalmer. Visit the Patwaon ki haveli with intricately designed latticed windows used by the ladies to view the external world. Also see the Gadisar Lake.Later, we go to Sam or Khuri village. Here we have loads of excitement in store for you. Upon arrival in the village you will be taken to the nearby sunset point on the dunes to see the sunset by camel carts. After the sunset return to the village where your dinner is arranged in mud huts. Later while you dine listen to the haunted tunes of the desert by the local artists and musicians. A lifetime experience. Dinner in the desert mud huts.</p>
</div>
<br></br>
<div className='contentbox'>
<h4  className='rajcultureday7'>DAY-07 : Jaisalmer – Jodhpur (300 kms/5 hrs approx) </h4>

    <p className='daycontent'>
    Today we reach Jodhpur the blue city by early afternoon.Post lunch session introduces you to the fabulous spice market of Jodhpur the 2nd largest city in Rajasthan. Walk along the market to feel the odours of the spices filling the air around you.Then go to Mandore gardens the then capital of Marwar to view some of the fabulous cenotaphs and the hall of local heroes of the wars. Drive back to Jodhpur and see the Umaid Bhawan Palace, the present residence of the king of Jodhpur. Retire to your hotel and relax.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajcultureday8'>DAY-08 :Jodhpur – Luni (40km) </h4>

<p className='daycontent'>After breakfast drive up to the majestic Mehrangarh fort, the stronghold of the Rathore princes from 1453AD. We see the superb collection of paintings and arms and ammunitions. The view of the city from the rampart walls is breath taking.Later an hours drive brings us to the world of animals – Luni, the palace amidst the more rustic life of Rajasthan. After lunch we take you on a safari to discover the hidden beauty of the jungle life. Here you would be visiting the Bishnoi villages. Bishnois are a tribe that respects life in every form – animals trees and even dead branches. They are vegetarians and even in the villages they protect the animal life and don't let anybody practice hunting in the vicinity of their villages. They live in a joint family system where the oldest member is generally the head of the family.Then we would like you to witness the opium ceremony with the Patels – a rich tribe. It is a ceremony prevelant in this part of Rajasthan where the youth offers opium to drink mixed with water to the elders of the family when they come back from work in the fields.</p>
</div>
<br></br>

<div className='contentbox'>
<h4 className='rajcultureday9'>DAY-09  : Luni – Kumbhalgarh (200km)</h4>

    <p className='daycontent'>
    This morning we drive through the arid Rajasthan to reach Kumbhalgarh the stronghold of the kingdom of Mewar built by Maharana Kumbha in 1453. Visit the palace and see the beautiful mural paintings in the Zenana mahal (ladies quarters). The view from the parapet wall is breath taking and this is where the soldiers used to be stationed to view the advent of the enemies. The fort has 7 gates through which we need to enter to gain access to the palaces. See the temple where Maharana Kumbha was treacherously killed by his own kith and kin during his prayer to win the seat of Mewar.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday10'>DAY-10 : Kumbhalgarh – Ranakpur – Udaipur (260km)
</h4>

    <p className='daycontent'>
    Leave Kumbhalgarh and reach Ranakpur a very big pilgrimage centre for the Jains. Just four hours drive from Udaipur is the eternal Jain pilgrimage centre of Ranakpur. Admire the workmanship from the 16th century. The temples are built out of marble and the main shrine of Adinath with 1444 columns is awe inspiring and none of these are similar. Also see the sun temple and the other temples in the campus. Proceed towards Udaipur and on the way stop by the fields to see the local way of farming and irrigation. Walk into the villages to mingle with the locals around to know more about their lifestyle. Reach Udaipur by late afternoon and check into your hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday11'>DAY-11 : Udaipur </h4>

    <p className='daycontent'>
    Visit the City Palace the richest palace museum in the entire Rajasthan. See the Peacock Square and the miniature painting school inside the palace premises. Udaipur has the prestige of housing the best miniature schools in the country under the royal patronage of the king of Udaipur. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter.In the evening we take a lake cruise on the placid waters of Lake Pichhola by sunset and visit the island palace Jagmandir where according to legends the Mughal Emperor Shah Jehan lived and got inspired to build the Taj mahal later.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday12'>DAY-12 :Udaipur – Deogarh (120km) </h4>

    <p className='daycontent'>
    Leave Udaipur and reach the temples of Nagda and Eklingji. The temple at Eklingji is a temple that belongs to the Maharana of Mewar (royal family) and he is often there to pray. The temple complex of Nagda is a group of temples of which only two remain intact and those are that of Saas and Bahu. Both the temples, of Shiva and of Vishnu, are from the 7th century.Continue towards Deogarh Palace where the royal family welcomes you to their hospitality. Enjoy the evening in the royal hospitality of Mewar.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday13'>DAY-13 : Deogarh – Ajmer – Pushkar – Rupangarh (150km) </h4>

    <p className='daycontent'>
    Today cross the entire stretch of Mewar to reach the most holy cities of Pushkar and Ajmer. Pushkar happens to be the holiest places according to the Hindus as it is considered that Lord Brahma (the Creator) and his wife Saraswati (the godess of learning and music) performed a ritual in the lake. Here we visit the only Brahma temple of the world before intermingling with the local colours in the local bazaar. Pushkar is the world famous for its cattle fair held each year in the month of October/November. Proceed towards Ajmer. Ajmer is the pious city for the Muslims. Surprisingly both the cities are at distance of 11km from each other. Visit the Durgah built in fond remembrance of Khwaja Muin ud Din Chisti where the Muslim pilgrims from all over India throng during the festival times. Later visit Mughal emperor Akbar's Palace where his son Jehangir received Sir Thomas Roe to grant him the permission of trade to East India Company with India, that changed the history of India completely and slowly and steadily India became colony of the British Empire.Reach Kishangarh and stay in the Palace of Maharaja Sawan Singh and see the mural paintings of this region that touched the epitome of art and culture during the reign of Maharaja Sawan Singh and master painter Nihalchand.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajcultureday14'>DAY-14 :Rupangarh – Jaipur (120km)</h4>

<p className='daycontent'>After breakfast continue towards Jaipur and reach by lunch-time and check into hotel.After lunch set off to see the Pink city visiting the observatory Jantar Mantar built in 1727 by Sawai Maharaja Jai Singh II who was a mathematician , an astronomer an astrologer and a great musician of his era. He built five observatories throughout India – Jaipur, Ujjain, Varanasi, Mathura and Delhi but only the one at Jaipur is functional.Then visit the City Palace of the Maharaja of Jaipur with a fabulous collection of robes and saris in cotton and silk with gold latticed work. The City Palace houses a collection of paintings also in the painting gallery and of course a collection of arms and ammunitions in the ammunition gallery. Later visit the carpet-weaving factory of Jaipur. Carpet weaving is an age old tradition in India.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday15'>DAY-15 : Jaipur</h4>

    <p className='daycontent'>
    Visit the Amber fort in the morning; included is an elephant ride at the Amber fort. See the marvelous Shish mahal built in the 17th century in a fusion style of Hindu and Islamic art. Also see the hall of public audiences and Maharaja Man Singh's Palace who had twelve queens.In the afternoon visit one of the many stone cutting and polishing stores in the city.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday16'>DAY-16 :Jaipur – Karauli </h4>

    <p className='daycontent'>
    Leave Jaipur after breakfast and reach Karauli by lunch-time. After lunch visit the palace in this region and the temple complex of this small village.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday17'>DAY-17 :Karauli – Bharatpur
</h4>

    <p className='daycontent'>
    In the morning leave Karauli and reach Bharatpur via Deeg, the kingdom of Maharaja Surajmal Jat. Visit the Hawa Mahal and the palace's intricate furniture preserved even to this day. This visit is a feast to the eyes.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajcultureday18'>DAY-18 : Bharatpur – Fatehpur Sikri – Agra

</h4>

    <p className='daycontent'>
    Early in the morning visit the ornithological reserve at Bharatpur – Keoladevi National Park. See the varied varieties of birds and animals during your tour inside the park. This is the only National Park where you get to see the rare species of the Siberian Crane during winter season.After lunch continue towards Fatehpur Sikri the Phantom city. Visit this abandoned city built by the Mughal emperor Akbar in the second half of the 16th century. Fatehpur Sikri was built by Akbar after his Hindu wife bore him the long awaited son who was born after the renowned sufi saint Salim Chisti blessed Jodhabai Akbar's Hindu wife with a son. Also visit Jodhabai's palace the biggest palace in Fatehpur Sikri even bigger than the emperor's palace himself as this was the queen who bore him his heir to the throne.Continue towards Agra and reach Agra by early evening. Relax at your hotel or go to the local bazaar to see the lifestyle of the people.</p>
</div>
<br></br>
<div className='contentbox'>
<h4  className='rajcultureday19'>DAY-19 : Agra </h4>

    <p className='daycontent'>
    Taj Mahal is awaiting your arrival today morning. A must see with the rising sun as the Taj Mahal is bathed in the golden rays of the morning sun. Built by Shah Jahan, the Taj is a white marble memorial that houses the tomb of his beautiful wife Mumtaz Mahal. This monument took 22 years to be completed and was designed, and planned by Persian architect Ustad Isa. Apart from its stunning design, balance and perfect symmetry, the Taj is also noted particularly for its elegant domes, intricately carved screens and some of the best inlay work ever seen anywhere in the world. Then see the Agra Fort built by Akbar where Shahjehan's own son Aurangzeb imprisoned Shahjahan. Post lunch visit Itmad ud Daulah – it is the first mughal building built out completely of marble. In many contexts it is referred to being the predecessor of the Taj Mahal. Then visit Sikandara the tomb of the great emperor Akbar.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajcultureday20'>DAY-20 :Agra – Delhi </h4>

<p className='daycontent'>After a leisurely start today proceed towards Delhi.</p>
</div>
<br></br>



</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Rajasthanculturetour