import React from 'react'
import './Rajasthanholiday.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import Enquiryform from '../components/Enquiryform'

const Rajasthanholiday = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2   className='rajholidaypackageheading' >
    Rajasthan Holiday Tour with Orcha-Khujuraho Varanasi (19 Nights/ 20 Days)
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span className='rajholidaylocation'> Delhi » Jaipur » Mandawa » Bikaner » Jaisalmer » Jodhpur » Ranakpur » Udaipur » Pushkar » Agra » Orchha » Khajuraho » Varanasi</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/JAIPUR.jpg" class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/desert.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/TAJMAHAL.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 className='rajholidayitinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4  className='rajholidayday1'>DAY-01 : Arrive Delhi </h4>

    <p className='daycontent'>
    Arrival at Delhi, our representative will meet you at arrival hall of IGI airport and assist you in check-in Hotel. Overnight stay at Delhi.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajholidayday2'>DAY-02 :Delhi Sightseing</h4>

<p className='daycontent'>
After an Breakfast at hotel. Take full day sightseeing of Delhi. New Delhi - The political and administrative powerhouse of the world largest democracy is a mixture of ancient and modern character.Old Delhi - Was built by the Mughal Emperor Shah Jahan in the first half of the 17th century.The new city Immediately to the south is the British built capital of New Delhi, a self - conscious attempt to match the imperial grandeur of the Mughal capital, while post independence Delhi has dramatically accelerated its suburban expansion.During your trip your would cover these tourist places - India Gate ( War Memorial ), Rashtrapati Bhavan, Parliament House, Birla Mandir ( Also known as Laxmi Narayan Temple ), Raj Ghat, Humayun's Tomb, Lotus Temple, Akshardham Temple, Qutab Minar, Jama Masjid.Overnight will be in Delhi.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday3'>DAY-03 :Delhi - Jaipur (260-kms / 6 hr.)</h4>

    <p className='daycontent'>
  
After an early breakfast in hotel. Departure to Rajasthan Capital - Jaipur Enroute, visit the Neemrana Fort Palace. As you reach to Jaipur check - in at your pre booked hotel and enjoy trip to cultural historical capital of Rajasthan – Jaipur, also popularly described as the Pink City. Upon arrival in the Pink City – Jaipur, our representative will assist you to transfer you to the designated hotel. Dinner and overnight stay will be at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday4'>DAY-04 :Jaipur sightseeing</h4>

    <p className='daycontent'>
   
    After breakfast in the morning, you will be taken to Amer Fort with an elephant ride and then after City Palace Museum. In the afternoon enjoy tour Jaigarh Fort, Nahargarh Fort and Galta Temples(Monkey Temple) in Jaipur. Rest of the day is free and optional enjoy Rajasthani style delecious Dinner with Traditional and Cultural Dance of Rajasthan and Pupet Show. Return to the hotel for overnight stay.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday5'>DAY-05 : Jaipur To Mandawa
</h4>

    <p className='daycontent'>
    
    Drive to Mandawa (166-kms / 4 hr.) and Pay a visit to the buildings at the bazaars of Mandawa, beautiful temples and intricate merchant Havelis or mansions. Overnight at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday6'>DAY-06 : Mandawa - Bikaner
</h4>

    <p className='daycontent'>
    After an early breakfast, drive to Bikaner – popularly described as Camel Country or Camel City, visiting en route medieval town of Fatehpur. Upon arrival in Bikaner, you will be transferred to the hotel. Spend rest of the day at leisure. Dinner and overnight will be at the hotel. Upon arrival check into the hotel. Overnight in Jaisalmer.</p>
</div>
<br></br>
<div className='contentbox'>
<h4  className='rajholidayday7'>DAY-07 :Bikaner - Jaisalmer </h4>

    <p className='daycontent'>
    Drive from Bikaner to Jaisalmer (326-kms / 7 hr.) Enroute, visit Fort Pokhran. Arrive Jaisalmer in the evening. Overnight at a hotel</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajholidayday8'>DAY-08 :Jaisalmer - Khuri - Jaisalmer </h4>

<p className='daycontent'>

Camel Ride Safari Day excursion to Khuri (40 km /1 hr.), an oasis village located in the sand dunes. Camel safari in the sand dunes of Khuri. Overnight at Hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday9'>DAY-09  :Jaisalmer</h4>

    <p className='daycontent'>

    Have a hearty breakfast at the hotel and embark on full day sightseeing tours of Jaisalmer City. Visit to the magnificent Golden Fort (Sonar Kila), stunning Havelis, terrific temples, Patwon-ki-Haveli, and Nathmalji-ki-Haveli. In the evening enjoy camel safari to the Sam Sand Dunes and get fascinated with mesmerizing sunset view. Dinner and overnight stay will be at the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday10'>DAY-10 : Jaisalmer - Jodhpur
</h4>

    <p className='daycontent'>
   

    Drive from Jaisalmer to Jodhpur (280-kms / 6 hr.). Arrive Jodhpur in the afternoon. Evening sightseeing tour of the Blue City. Visit the Mehrangarh Fortress & Umaid Bhawan Palace museum.Overnight at the hotel</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday11'>DAY-11 : Udiapur Sightseeing Jodhpur - Ranakpur</h4>

    <p className='daycontent'>
   
Take up a early morning safari in the desert countryside. Visit the, Raika herdsmen, bird rich lakes, potters, weaver Bishnoi hamlets, leather craftsmen and wildlife habitats. Drive to Ranakpur in the afternoon (160-kms / 4 hr.). Arrival at Ranakpur in the evening.Overnight at a hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday12'>DAY-12 :Ranakpur - Udaipur </h4>

    <p className='daycontent'>
    Morning sightseeing tour of the Ranakpur Temples. Drive to Udaipur (96-kms / 3 hr.) in the afternoon. Arrival at Udaipur in the evening. Overnight at a hotel or a tourist lodge.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday13'>DAY-13 :Udaipur Lake City</h4>

    <p className='daycontent'>
  
Morning sightseeing tour of the romantic city of Udaipur. Visit to the City Palace Complex (the largest palace complex in Rajasthan). You will also visit lovely medieval Jagdish Temple and Sahelion-ki-Bari. Spend rest of the day at leisure. Dinner and overnight stay will be at the hotel.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='rajholidayday14'>DAY-14 :Udaipur -Pushker</h4>

<p className='daycontent'>

Catch the connecting flight to Delhi in the morning from Udaipur. Arrive in Delhi and transfer to the hotel. Overnight in the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday15'>DAY-15 : Pushker - Agra</h4>

    <p className='daycontent'>
    
    Visit the Taj Mahal, described by the Indian classical poet Tagore as a 'tear on the face of eternity', the Taj Mahal is undoubtedly the zenith of Moghul architecture.An expression of undying love by Emperor Shah Jahan to immortalise his dead empress, Mumtaz Mahal, the satin smooth marble mausoleum was built over a period of 22 years from 1623 to 1645 A.D., using a work force of 22,000 artisans. It was meticulously planned and perfectly executed with carefully designed garden and adjacent buildings to compliment the entire environment.Time permitting, a brief visit to see the local craftsmen doing marble inlay work.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday16'>DAY-16 :Agra - Orcha</h4>

    <p className='daycontent'>
    Drive to Orcha enroute to the Gawalior Fort. After lunch, way to Orcha. One of the most famous destinations in Central India for cultural holidays and architectural tours, Orchha on the banks of Betwa River in Madhya Pradesh attracts thousands of visitors annually. Orchha was the seat of power of the former princely state of Orchha in the Bundelkhand region of Madhya Pradesh, approximately 15 km from Jhansi. Orchha offers visitors numerous sights to be seen, and tours to Orchha include visits to the Fort Complex which contains the Rajmandir, Jahangir Mahal, and the Rai Parveen Mahal. Other sights include the Lakshminarayan Temple, Phool Bagh, Chaturbhuj Temple, and the 14 Cenotaphs. Orchha is affordably accessible with the best travel deals and packages. Overnight in the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday17'>DAY-17 :Orcha - Khujuraho
</h4>

    <p className='daycontent'>
    After Breakfast, sightseeing all temples of Khujuraho. A tiny picturesque village in Madhya Pradesh in India, Khajuraho is famous for housing the largest group of medieval Hindu and Jain temples rich in their erotic sculpture. A World Heritage Site, there are sensuous images of men and women carved on the temple walls in Khajuraho. Explore the beauty of human emotions on these temples on tours to Khajuraho. Make your holidays and vacations to Khajuraho complete with bicycle rides around the village. Overnight stay in the hotel.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday18'>DAY-18 :Khujuraho to Varanasi

</h4>

    <p className='daycontent'>
    Drive from Khujuraho to Varanasi (400-kms / 9 hr.). Arrive Varanasi in the Evening. Overnight at the hotel.</p>
</div>
<br></br>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday19'>DAY-19 :Khujuraho to VaranasiVaranasi Sightseeing

</h4>

    <p className='daycontent'>
    Early Morning boat ride. After lunch, Sarnath temple. Varanasi, Benaras, Banaras, Kashi - this city on the banks of river Ganga (nullGanges) has been known by all these names through its three millennia long history. A history that is all the more remarkable as its great contemporaries - Athens, Jerusalem and Beijing â have long moved away from their ancient way of life. Any attempt to encapsulate the essence of Varanasi is to touch the very core of Indian Spiritual Thought. Much of India's inclusive ethos stems from here. A place where mosques built on sacred temples are protected by law; a place where Bismillah Khan, a pious Shi'ite Muslim musician, and a devotee of Hindu Goddess Saraswati, played shehnai at the portals of Vishwanath Temple, remains India's pre-eminent place for spiritual quest.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='rajholidayday20'>DAY-20 :Varanasi Railway Station or Airport Drop

</h4>
</div>
</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Rajasthanholiday