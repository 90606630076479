import React from 'react'
import CONTACTUS from '../rinkuuncle/img/CONTACTUS.png'
// 
import './Contactus.css'
import Popularcard from '../components/Popularcard'
import Footer from '../components/Footer'
// import Enquiryform from '../components/Enquiryform'
import Contactsection from '../components/Contactsection'
const Contactus = () => {
  return (
    <div>
       {/* <!--main image  --> */}
        <div >
            <img src={CONTACTUS} className='contactus_image'></img>
        </div>


        {/* <!-- contact us heading --> */}
         <h3 className="contact_heading">contact&nbsp; details</h3>


         {/* <!-- horizontal line --> */}
         <div className='uppersleepingline'></div>



         {/* <!-- contact details --> */}
         
        <div className="container main_content">
            <div className="row">
                <div className="col-md-5  ">
                    <p id="office_heading">office details</p>
                    <br/>
                    
                        <h4> ADDRESS </h4>   
                        <p class="address_detail " >
                            426 bhai parmanand colony delhi-110009
                        </p>

                        <h4 > whatsapp </h4>   
                        <p class="mobile_detail">
                            +91-9711443099 , +91-9811739850 
                        </p>
                        <h4 > EMAIL</h4>   
                        <a href='' class="email_detail">RainbowTravelIndia@gmail.com</a> 
                    
                </div>

                <div className="col-md-2 ">
                    <div style={{}} className='standingline'></div>
                </div>

                <div className="col-md-5">
                    <p id="office_timing_heading">office timing</p>

                    <br/>
                    <p className="office_time">
                        9:00 AM TO 7:00 PM<br/>
                    </p>
                    <p className="facility">facility available</p>
                </div>
            </div>
        </div>
        {/* <!-- contact form heading --> */}
        <div className="contact_form_head">
            contact &nbsp;with&nbsp; us 
        </div>
        <div className='sleepingline'></div> 
        
        {/* <!-- contact us form --> */}
       
        <div>
            <Contactsection></Contactsection>
        </div>

        <div>
        <Popularcard></Popularcard>
        </div>

        <div>
        <Footer></Footer>
        </div>
                  
   </div>
    
  )
}

export default Contactus;