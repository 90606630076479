import React from 'react'
import ABOUTUS from '../rinkuuncle/img/ABOUTUS.png'
import Popularcard from '../components/Popularcard';
import './About.css';
import Footer from '../components/Footer';

const About = () => {
  return (
    <div>
      <div >
           <img src={ABOUTUS}  className='contactusimg'></img>
        </div>
   

        <div className="container rainbow_travel_container">
            <div className="row">
                <div className="col-md-12">
                    <div >
                        <p className="main_head"> Rainbow Travel India</p>
                        <p className="guest">GUEST IS GOD ..... <span style={{color: "orange"}}>अतिथि</span><span style={{color: "blue"}}> देवो</span><span style={{color: "green"}}> भव</span> </p>
                            <p>

                                <h5>Welcome to Rainbow Travel India!</h5>
                                
                               <p> At Rainbow Travel India, we believe that travel is not just about reaching a destination; it’s about experiencing the world in all its colors. Founded by Rinku Sharma, our tour and travel agency is dedicated to creating unforgettable journeys tailored to your unique interests and desires.
                               </p>
                                <h5>Our Story</h5>
                                
                               <p> With a passion for exploring new horizons and a commitment to delivering exceptional travel experiences, Rinku Sharma established Rainbow Travel India with the vision of making travel dreams come true. What began as a personal passion has grown into a full-fledged agency that combines expertise, creativity, and a personal touch to craft journeys that resonate with every traveler.
                               </p>
                                <h5>Our Mission</h5>
                                
                               <p>Our mission is simple: to provide our clients with seamless, personalized travel experiences that inspire and delight. Whether you’re seeking a relaxing getaway, an adventurous expedition, or a cultural immersion, our team is here to guide you every step of the way. We take pride in our attention to detail, our knowledge of diverse destinations, and our dedication to ensuring that each trip is a masterpiece of joy and discovery.
                                </p>

                                <h5>What Sets Us Apart</h5>
                                
                                <p>- Personalized Service:We understand that every traveler is unique. Our bespoke travel planning ensures that every itinerary is tailored to fit your individual preferences, from luxurious escapes to off-the-beaten-path adventures.
                                </p>
                                <p> - Expert Guidance: With years of experience in the travel industry, Rinku Sharma and the Rainbow Travel India team offer unparalleled expertise and insider knowledge to help you navigate your journey with confidence and ease.
                                </p>
                                <p>- Customer Satisfaction: Your satisfaction is our priority. We’re committed to providing exceptional service from the moment you contact us until you return home with cherished memories.
                                </p>
                                <h5>Join Us on a Journey</h5>
                                
                               <p> Embark on a journey with Rainbow Travel India and discover the world through a spectrum of experiences. Whether you’re dreaming of a serene beach vacation, a thrilling safari, or a cultural exploration, we’re here to turn your travel dreams into reality.
                               </p> 
                           
                                <h5>Contact Us Today</h5>
                                
                                Ready to start your adventure? Reach out to us to begin planning your next incredible journey. Let’s make your travel dreams come true!
                                
                                ---
                                
                                Feel free to adjust any details to better fit your brand's voice or specific offerings!</p>
                    </div>
                </div>
            </div>
        </div>
        <br></br>

        <Popularcard></Popularcard>
        <Footer></Footer>

      
       
    </div>
  )
}

export default About