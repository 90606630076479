import React from 'react'
import './Goldentrianglewithtigersafari.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import indiagate from '../rinkuuncle/img/indiagate.jpg'
import JAIPUR from '../rinkuuncle/img/JAIPUR.jpg'
import tiger from '../rinkuuncle/img/tiger.jpg'
import location from '../rinkuuncle/img/location.png';
import Enquiryform from '../components/Enquiryform'

const Goldentrianglewithtigersafari = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  style={{}}className='gtwithtigerpackageheading' >
         GOLDEN TRIANGLE WITH TIGER SAFARI  (5 Nights/ 6 Days)
    </h2>

    <p >
        <span ><img src={location} alt=""  style={{  height: "22px",}}></img></span> 

        <span className='gttigerlocation'> Delhi » Agra » Fatehpursikri » Bharatpur » Ranthambore » Jaipur » Delhi</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src={tiger} class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={indiagate} class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src={JAIPUR} class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 className='gttigertouritinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4  className='gttigerdayone'>DAY-01 </h4>

    <p className='daycontent'>
    Arriving at Delhi Meet and assist by our representative upon your arrival at the airport.Then drive to your hotel with your driver and car.In the morning take a city tour covering Laxmi Naryan Temple-Place of GOD,India Gate-The memorial of martyrs,Parliament House-The Government headquarters,Lotus Temple,Qutub Minar,In the afternoon take a city tour of delhi covering Jama Masjid-The largest mosque in Asia,Red Fort-Red stone magic,Gandhi memorial-The momoir of the father of nation.In evening enjoy indian treditional and cultural folk dance show.Overnight at hotel.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='gttigerdaytwo'>DAY-02 </h4>

<p className='daycontent'>Delhi- Agra: (210kms 4 hrs drive) In the early morning after breakfasts move to Agra, On arrival check in to the hotel after that visit world famous TajMahal(with guide) built by king Shahjahan in the memory of his Queen wife Mumtaj Mahal Take you own time to visit the beauty of Taj. After lunch visit Red Fort &Sikandra in Agra.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gttigerdaythree'>DAY-03 </h4>

    <p className='daycontent'>
    Agra-Ranthambore(295 Kms,6/7 hours drive) After breakfast at the hotel, leave for Ranthambhore on the way visit Ghost Town Fatehpur Sikri and world famous Birds Sanctuary Kewla Deep National Park. On arrival Ranthambhore check in at Hotel / lodge / camp. Enjoy Your Dinner and Overnight stay in at Hotel / lodge / camp.
</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gttigerdayfour'>DAY-04 </h4>

    <p className='daycontent'>
    Ranthambhore Jaipur Next Day Early in the morning you will enjoy and visit to Tiger safari in National Park, after that proceed for Jaipur (Pink City) in between we visit between visit famous Monkey Temple(Galta ji). On arrival Jaipur check in at Hotel. In the evening visit Hawa Mahal (Place of winds), and nice walk in Pink City .</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gttigerdayfive'>DAY-05 </h4>

    <p className='daycontent'>
    Jaipur Jaipur is the center of tourist of the entire world. Our Guide meets at Jaipur. After breakfast in the morning, you will be taken excursion tour to Amer Fort with an elephant ride, Water Palace and City Palace. In the afternoon enjoy tour Jaigarh Fort, Nahargarh Fort in Jaipur. Rest of the day is free and optional visit to Rajasthani Delecious Dinner with Traditional and Cutural Dance and Pupet Show .Return to the hotel for overnight stay at hotel</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='gttigerdaysix'>DAY-06 </h4>

    <p className='daycontent'>
    aipur-Delhi(265 Kms,5/6 hours drive) Next morning we leave for Delhi, on arrival at Delhi transfer to the International airport/hotel for onward journey with sweet memories of Golden Triangle with Tiger Safari tour with Heritage Tour India</p>
</div>
</div>

<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>

</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Goldentrianglewithtigersafari