import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Contactus from './pages/Contactus';
import About from './pages/About';
import Agra from './pages/Agra'
import Cookingclass from './pages/Cookingclass';
import Goldentriangle from './pages/Goldentriangle';
import Goldentrianglewithpuskar from './pages/Goldentrianglewithpuskar';
import Goldentrianglewithtigersafari from './pages/Goldentrianglewithtigersafari';
import Jaipurdaytrip from './pages/Jaipurdaytrip';
import Junglesafari from './pages/Junglesafari';
import Rajasthancompeletetour from './pages/Rajasthancompeletetour';
import Rajasthanculturetour from './pages/Rajasthanculturetour';
import Rajasthanholiday from './pages/Rajasthanholiday';
import Rajasthanpalacetour from './pages/Rajasthanpalacetour';
import Royalgujrattour from './pages/Royalgujrattour';
import Spritualtour from './pages/Spritualtour';
import Ourpackages from './pages/Ourpackages';
// import Contactform from './components/Contactform';
import Romanticrajasthan from './pages/Romanticrajasthan'
import Spritualsameday from './pages/Spritualsameday';
import Gallery from './pages/Gallery';
import Privacypolicy from './pages/Privacypolicy';
import Review from './pages/Review';
import Enquiryform from './components/Enquiryform';
import Allpackages from './pages/Allpackages';
function App() {
  return (
    <div className="App">
        <Header></Header>
        <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/ourpackages/" element={<Allpackages></Allpackages>}>
          <Route index element={<Ourpackages></Ourpackages>}></Route>
          <Route path='jaipurdaytrip' element={<Jaipurdaytrip></Jaipurdaytrip>}></Route>
          <Route path='goldentrianglewithpushkar' element={<Goldentrianglewithpuskar></Goldentrianglewithpuskar>}></Route>
          <Route path='junglesafari' element={<Junglesafari></Junglesafari>}></Route>
          <Route path='rajasthanholiday' element={<Rajasthanholiday></Rajasthanholiday>}></Route>
          <Route path='royalgujrattour' element={<Royalgujrattour></Royalgujrattour>}></Route>
          <Route path='rajasthancompeletetour' element={<Rajasthancompeletetour></Rajasthancompeletetour>}></Route>
          <Route path='rajasthanculturetour' element={<Rajasthanculturetour></Rajasthanculturetour>}></Route>
          <Route path='rajasthanpalacetour' element={<Rajasthanpalacetour></Rajasthanpalacetour>}></Route>
          <Route path='cookingclass' element={<Cookingclass></Cookingclass>}></Route>
        </Route>
        <Route path="/aboutus" element={<About></About>}></Route>
        <Route path="/privacypolicy" element={<Privacypolicy></Privacypolicy>}></Route>
        <Route path="/gallery" element={<Gallery></Gallery>}></Route>
        <Route path="/review" element={<Review></Review>}></Route>
        <Route path="/contactus" element={<Contactus></Contactus>}></Route>
        <Route path='/agra' element={<Agra></Agra>}></Route>
        <Route path='/goldentriangle' element={<Goldentriangle></Goldentriangle>}></Route>
        <Route path='/goldentrianglewithtigersafari' element={<Goldentrianglewithtigersafari></Goldentrianglewithtigersafari>}></Route>
        <Route path='/spritualsameday' element={<Spritualsameday></Spritualsameday>}></Route>
        <Route path='/spritualtour' element={<Spritualtour></Spritualtour>}></Route>
        <Route path='/romanticrajasthan' element={<Romanticrajasthan></Romanticrajasthan>}></Route>   
      </Routes>
    </div>
  );
}

export default App;
