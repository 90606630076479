import React from 'react'
import Pakages from '../components/Pakages'
import ourpackages from '../rinkuuncle/img/ourpackages.png'
import'./Ourpackages.css'
import tigersafari from '../rinkuuncle/img/tigersafari.jpg'

import JAIPUR from'../rinkuuncle/img/JAIPUR.jpg'
import goldentrianglepuskar from '../rinkuuncle/img/goldentrianglepuskar.jpg'
import rajasthanholiday from '../rinkuuncle/img/rajasthanholiday.jpg'
import gujrat from '../rinkuuncle/img/gujrat.jpeg'
import rajasthanculture from '../rinkuuncle/img/rajasthanculture.jpeg'
import rajasthanpalace from '../rinkuuncle/img/rajasthanpalace.jpg'
import rajsthancomplete from '../rinkuuncle/img/rajasthancomplete.jpeg'
import cooking from '../rinkuuncle/img/cooking.jpeg'
import { Link, Outlet } from 'react-router-dom'
import Footer from '../components/Footer'

const Ourpackages = () => {
  return (
    <div>
        <img src={ourpackages} className='ourpackagesimg'></img>
        <Pakages></Pakages>

        <div className="container packages">
            <div className="row">
                <div className="col-md-3 ">
                    <Link to="jaipurdaytrip" className='packagelink'><div className='package'>
                        <img src={JAIPUR} className='packageimg'></img>
                        <h5 className='packagehead'>JAIPUR DAY TRIP</h5>
                        <p>Explore the stunning architecture and vibrant culture of Jaipur in a single unforgettable day.</p>
                    </div>
                    </Link> 
                </div>

                <div className="col-md-3">
                    <Link to="goldentrianglewithpushkar"className='packagelink'><div className='package'>
                        <img src={goldentrianglepuskar} className='packageimg'></img>
                        <h5 className='packagehead'>GOLDEN TRIANGLE WITH PUSKAR</h5>
                        <p>Discover India's iconic Golden Triangle while experiencing the spiritual charm of Pushkar.</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md-3">
                   <Link to="junglesafari"className='packagelink'><div className='package'>
                        <img src={tigersafari} className='packageimg'></img>
                        <h5 className='packagehead'>JUNGLE SAFARI</h5>
                        <p>Embark on an adventurous jungle safari to witness Rajasthan's wildlife in their natural habitat.</p>
                    </div>
                    </Link>
                </div>
                
            </div>
        </div>

        <div className="container packages">
            <div className="row">
                <div className="col-md-3 ">
                    <Link to="royalgujrattour"className='packagelink'><div className='package'>
                        <img src={gujrat} className='packageimg'></img>
                        <h5 className='packagehead'>ROYAL GUJRAT TOUR</h5>
                        <p>Experience the royal heritage and diverse culture of Gujarat, from majestic palaces to vibrant markets.</p>
                    </div>
                    </Link>
                </div>

                <div className="col-md-3">
                    <Link to="spritualtour"className='packagelink'> <div className='package'>
                        <img src={rajasthanholiday} className='packageimg'></img>
                        <h5 className='packagehead'>SPRITUAL TOUR</h5>
                        <p>Embark on a soul-enriching journey with our Spiritual Tour, crafted to guide you through the heart of spirituality.Engage in transformative activiti like guided meditation.</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md-3">
                    <Link to="rajasthancompeletetour"className='packagelink'><div className='package'>
                        <img src={rajsthancomplete} className='packageimg'></img>
                        <h5 className='packagehead'>RAJASTHAN COMPLETE TOUR</h5>
                        <p> Enjoy a comprehensive journey through Rajasthan's most captivating cities, palaces, and deserts.</p>
                    </div>
                    </Link>
                </div>
                
            </div>
        </div>

        <div className="container packages">
            <div className="row">
                <div className="col-md-3 ">
                    <Link to="rajasthanculturetour"className='packagelink'><div className='package'>
                        <img src={rajasthanculture} className='packageimg'></img>
                        <h5 className='packagehead'>RAJASTHAN CULTURE TOUR</h5>
                        <p>Dive deep into Rajasthan’s rich traditions, folk arts, and culinary delights for an authentic cultural experience.</p>
                    </div>
                    </Link>
                </div>

                <div className="col-md-3">
                    <Link to="rajasthanpalacetour"className='packagelink'> <div className='package'>
                        <img src={rajasthanpalace} className='packageimg'></img>
                        <h5 className='packagehead'>RAJASTHAN PALACE TOUR</h5>
                        <p>Explore the opulent palaces and forts of Rajasthan, each telling stories of a glorious past.</p>
                    </div>
                    </Link>
                </div>
                <div className="col-md-3">
                    <Link to="cookingclass"className='packagelink'><div className='package'>
                        <img src={cooking} className='packageimg'></img>
                        <h5 className='packagehead'>COOKING CLASS</h5>
                        <p>Learn the secrets of traditional Rajasthani cuisine with hands-on cooking classes in a local setting.</p>
                    </div>
                    </Link>
                </div>
                
            </div>
        </div>

            <div>
            <Footer></Footer>
            </div>
        <Outlet></Outlet>
    </div>
    
  )
}

export default Ourpackages