import React from 'react'
import cooking2 from '../rinkuuncle/img/cooking2.jpg'
import cooking1 from '../rinkuuncle/img/cooking1.jpg'
import location from '../rinkuuncle/img/location.png'
import cooking from '../rinkuuncle/img/cooking.jpeg'
import Popularcard from '../components/Popularcard'
import Footer from '../components/Footer'
import './Cookingclass.css';
import Enquiryform from '../components/Enquiryform'
const Cookingclass = () => {
  return (
    <div>
        {/* <!-- horizontal line --> */}
        <div className='horizontalline'></div>

       {/* <!-- [package heading] --> */}
        <h2  className='cookingpackageheading' >
        COOKING CLASS (PURE VEGETERIAN FOOD)
        </h2>

        <p >
            <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

            <span style={{}} className='cookinglocation'>AT MY HOME</span>
         </p>
      

        {/* <!-- horizontal line --> */}
        <div className='horizontalline'></div>

        {/* <!-- package details --> */}
         <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img src={cooking} class="d-block w-100" alt="..." height="450px"></img>
      </div>
      <div className="carousel-item">
        <img src={cooking1} class="d-block w-100" alt="..."height="450px"></img>
      </div>
      <div className="carousel-item">
        <img src={cooking2} class="d-block w-100" alt="..."height="450px"></img>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
{/* <!-- tour itinerary heading --> */}
  <h3  className='cookingitinerary'>TOUR ITINERARY</h3>
  {/* <!-- tour itinary content box --> */}
  <div className='contentbox'>
    {/* <h4  style={{marginLeft:"-200px", fontWeight:"bold",marginbottom: "30px"}}>DAY-01 : Arrive Agra - on arrival visit the Agra Red Fort</h4> */}
    
        <p className='daycontent'>
        Join us for an immersive Indian cooking class designed specifically for tourists eager to explore the vibrant flavors of Indian cuisine! In this hands-on experience, you’ll learn to prepare classic vegetarian dishes such as Aloo Gobi, Chole, and Vegetable Biryani, guided by expert chefs who will share tips on traditional spices and cooking techniques. Enjoy the delightful aromas and tastes as you savor your creations alongside refreshing drinks and authentic snacks. This culinary journey not only offers delicious food but also a deeper understanding of India’s rich culinary heritage, making it a memorable experience for every food lover!</p>
  </div>
    </div>
    <div className="col-md-4 ">
       <Enquiryform></Enquiryform>
    </div>
    </div>
    </div>

    <div>
        <Popularcard></Popularcard>
    </div>

    <div>
        <Footer></Footer>
    </div>

    </div>
  )
}

export default Cookingclass