import React from 'react'
import Footer from '../components/Footer'
import packageheading from '../rinkuuncle/img/packageheading.png'
import ganeshji1 from '../rinkuuncle/img/ganeshji1.jpg'
import client23 from '../rinkuuncle/img/client23.jpeg'
import OURDRIVER from '../rinkuuncle/img/OURDRIVER.png'
import rivermain from '../rinkuuncle/img/rivermain.jpeg'
import rajasthanmain from '../rinkuuncle/img/rajasthanmain.jpeg'
import tajmahalmain from '../rinkuuncle/img/tajmahalmain.jpeg'
import handshake from '../rinkuuncle/img/handshake.png'
import Pakages from '../components/Pakages'
import Enquiryform from '../components/Enquiryform'
import { Link, useNavigate } from 'react-router-dom'
import './Home.css'
import Popularcard from '../components/Popularcard'
import Reviewslider from '../components/Reviewslider'

import Services from '../components/Services'
import Contactsection from '../components/Contactsection'
const Home = () => {
  const navigate=useNavigate();
function clickhandler()
{
navigate('/Ourpackages')
}
  return (
<div>
      
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "0px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src={ganeshji1} class="d-block w-100" alt="..." height="560px"></img>
  </div>
  <div className="carousel-item">
    <img src={rajasthanmain} class="d-block w-100" alt="..."height="560px"></img>
  </div>
  <div className="carousel-item">
    <img src={client23} class="d-block w-100" alt="..."height="560px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>

 {/* driver */}

          <div className="driver">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className='driverhead'>WELCOME TO RAINBOW TRAVEL INDIA</h1>
                            <img src='/rainbowflag.png' className='rainbowflag'></img>
                            <p className='companyintro'>
                              RainbowTravel India is a progressive and inclusive travel company that proudly serves the needs of the Rainbow Community (LGBTQ+ community) by offering specialized tours and travel experiences across India. With a passion for promoting diversity, equality, and inclusivity, RainbowTravel India is dedicated to creating safe, welcoming, and enriching travel opportunities for LGBTQ+ individuals, couples, and allies.We aim to showcase India’s beautiful landscapes, vibrant cities, and sacred destinations while providing a safe and supportive environment for LGBTQ+ travelers to explore the country with confidence. Our services ensure that your travel experience is fully catered to your needs, preferences, and safety, giving you the freedom to enjoy the trip of a lifetime.
                            </p>
                            <h3 className='rinkuhead'>RINKU SHARMA</h3>
                            <p className='driverintro'>
                            Meet Rinku Sharma, the visionary behind "RAINBOW TRAVEL INDIA COMPANY". With a career spanning since 1998, Rinku has dedicated over two decades that cater to the unique needs of rainbow travelers. In 2014, driven by a passion for creating unforgettable travel experiences, Rinku founded our company with a mission to transform the way you explore the world.
                           Rinku's vision is to transform the tourism landscape in India by promoting acceptance, understanding, and joy among all communities. With a professional background in tourism and a personal commitment to supporting LGBTQ+ rights, Rinku ensures that every customer receives personalized attention, guidance, and a tour experience that goes beyond the ordinary.. Join us, and let Rinku and our team turn your travel dreams into reality.
                            </p>
                       
                        </div>
                    </div>
                </div>
          </div>  

        {/* packages */}
        <div className='packagediv'>
        
            <img src={packageheading} className='packageheading'></img>
        
            <Pakages></Pakages>
          <button className='moretourbtn' onClick={clickhandler}>MORE TOURS</button>
        </div>
       

        {/* services */}
       
        <div>
            <Services></Services>
        </div>
               
        <br></br>
        {/* contactform */}
        
        <div >
           <Contactsection></Contactsection>
        </div>

        

            {/* review */}
            <div>
              <Reviewslider></Reviewslider>
            </div>


           
         
         <br></br>

         {/* popular card */}
            <div>
              <Popularcard></Popularcard>
            </div>
            
            {/* thanking */}

            <div className=" thanking_card">
                <img src={handshake}  className="thanking_img"></img>
                
                <div style={{}}className='thankingdiv'>
                "RAINBOW TRAVEL INDIA" THANKING TO ALL OUR VALUABLE CUSTOMERS!
                </div>

                <div>
                    <h2 style={{}}className='thankingline'>WISH YOU A HAPPY AND COMFORTABLE JOURNEY </h2>
                </div>
            </div>

            {/* footer */}
            <div>
              <Footer></Footer>
            </div> 

            

</div>
    
  )
}

export default Home