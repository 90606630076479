import React from 'react'
import './Junglesafari.css'
import Footer from '../components/Footer'
import Popularcard from '../components/Popularcard'
import junglesafari2 from '../rinkuuncle/img/junglesafari2.jpg'
import junglesafari from '../rinkuuncle/img/junglesafari.jpg'
import tiger from '../rinkuuncle/img/tiger.jpg'
import location from '../rinkuuncle/img/location.png';
import Enquiryform from '../components/Enquiryform'

const Junglesafari = () => {
  return (
    <div>
    {/* <!-- horizontal line --> */}
    <div className='horizontalline'></div>

   {/* <!-- [package heading] --> */}
    <h2  className='junglepackageheading' >
         JUNGLE SAFARI  (11 Nights/ 12 Days)
    </h2>

    <p >
        <span ><img src="/location.png" alt=""  style={{  height: "22px",}}></img></span> 

        <span className='junglelocation'>  Delhi » Ranthambore » Orchha » Panna » Bandhavgarh » Kanha »Nagpur</span>
     </p>
  

    {/* <!-- horizontal line --> */}
    <div className='horizontaline'></div>

    {/* <!-- package details --> */}
     <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="/tiger.jpg" class="d-block w-100" alt="..." height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/junglesafari.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
  <div className="carousel-item">
    <img src="/junglesafari2.jpg" class="d-block w-100" alt="..."height="450px"></img>
  </div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
{/* <!-- tour itinerary heading --> */}
<h3 style={{ }} className='jungletouritinerary'>TOUR ITINERARY</h3>
{/* <!-- tour itinary content box --> */}
<div className='contentbox'>
<h4  style={{}} className='jaipurdayone'>DAY-01 : Pickup from Delhi Airport </h4>

    <p className='content'>
    On arrival in Delhi, get greeted by our tour representative who will transfer you to your pre-booked hotel. Check in at the hotel for a relaxing overnight stay.</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='jaipurdaytwo'>DAY-02 :Ranthambore</h4>

<p className='content'>Ranthambore National Park is a vast wildlife reserve near the town of Sawai Madhopur in Rajasthan, northern India. It is a former royal hunting ground and home to tigers, leopards and marsh crocodiles. Its landmarks include the imposing 10th-century Ranthambore Fort, on a hilltop, and the Ganesh Mandir temple.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='jaipurdaythree'>DAY-03 : Ranthambhore </h4>

    <p className='content' >
    Morning and Evening jungle safari .
</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='jaipurdayfour'>DAY-04 :Orchha </h4>

    <p className='content'>
    Orchha (or Urchha) is a town in Tikamgarh district of Madhya Pradesh state, India. The town was established by Rudra Pratap Singh some time after 1501, as the seat of an eponymous former princely state of central India, in the Bundelkhand region</p>
</div>
<br></br>

<div className='contentbox'>
<h4 className='jaipurdayfive'>DAY-05 : Orchha </h4>

    <p className='content'>
    The Orchha Fort complex, which houses a large number of ancient monuments consisting of the fort, palaces, temple and other edifices, is located in the Orchha town in the Indian state of Madhya Pradesh. The fort and other structures within it were built by the Bundela Rajputs starting from early 16th century by King Rudra Pratap Singh of the Orchha State and others who followed him.</p>
</div>
<br></br>

<div className='contentbox'>
<h4 className='jaipurdaysix' >DAY-06 : Panna </h4>

    <p className='content'>
    Panna is a town and a municipality in Panna district in the Indian state of Madhya Pradesh. It is famous for its diamond mines. It is the administrative center of Panna District.</p>
</div>
<br></br>
<div className='contentbox'>
<h4  className='jaipurdayseven'>DAY-07 : Panna </h4>

    <p className='content'>
    Panna National Park located in Panna and Chattarpur district of Madhya Pradesh is formally being renowned as the 22nd tiger reserve of India and fifth in Madhya Pradesh.
</p>
</div>

<br></br>

<div className='contentbox'>
<h4  className='jaipurdayeight'>DAY-08 : Bandhavgarh </h4>

<p className='content'>The wildlife of the Bandhavgarh National Park is truly the most glittering parts of the Aravali regions. The Park is simply known for the various species of wild creatures and is best known for the preservation of the most astonishing tiger species. The presence of the abundance of tiger species in Bandhavgarh has drove many animal lovers in this explicit arena. Appreciate the wilderness hidden behind every rugged way of Bandhavgarh with the amazing wildlife tour in Bandhavgarh Reserve.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='jaipurdaynine'>DAY-09  : Bandhavgarh </h4>

    <p className='content' >
    Morning and Evening jungle safari .
</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='jaipurdayten'>DAY-10 : Kanha</h4>

    <p className='content'>
    Kanha Tiger Reserve, also called Kanha National Park, is one of the tiger reserves of India and the largest national park of Madhya Pradesh, state in the heart of India.</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='jaipurdayeleven'>DAY-11 : Kanha </h4>

    <p className='content' >
    Morning and Evening jungle safari .</p>
</div>
<br></br>

<div className='contentbox'>
<h4  className='jaipurdaytweleve'>DAY-12 : Nagpur </h4>

    <p className='content' >
    Drop at Airport of Nagpur.</p>
</div>



</div>
<div className="col-md-4 ">
   <Enquiryform></Enquiryform>
</div>
</div>
</div>

<div>
    <Popularcard></Popularcard>
</div>

<div>
    <Footer></Footer>
</div>

</div>
  )
}

export default Junglesafari