import React from 'react'
import './Reviewslider.css'
import Slider from "react-slick";
import reviewhead from '../rinkuuncle/img/reviewhead.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Reviewslider = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <div style={{ }}className='reviewsectiondiv'>
        <img src={reviewhead}  className='reviewhead'></img>
        <Slider {...settings}>
            {data.map((d)=>
                
                <div className='card'>
                    <div className='cardimg'>
                        <img src={d.image} className='img'></img>
                    </div>
                    <div className='cardcontent'>
                        <p className='clientname'>{d.name}</p>
                        <p className='review'>{d.review}</p>
                    </div>
                </div>
                
            )}
            </Slider>
    </div>
  )
}

const data=[
    {
        name:'Sophie Marquet',
        image:'/profile.png',
        ratingimg:'/star.png',
        review:'Rinku helped us during our business trip in Delhi. Great advice, smooth driver and super nice. We’ll definitely ask him to support us again !',
    },
    {
        name:'Pierre-Paul ',
        image:'/profile.png',
        ratingimg:'/star.png',
        review:'We asked for Rinku’s services during a business trip to Delhi and they were outstanding. Rinku was very reactive and efficient,professionalism and friendliness.',
    },
    {
        
        name:'Milina',
        image:'/profile.png',
        ratingimg:'/star.png',
        review:'Well organised tour (although it was very spontaneous from our side) and excellent driving and professionalism overall. I can recommend him to anyone wanting to discover India by car',
    },
    {
        
        name:'Calvin',
        image:'/profile.png',
        ratingimg:'/star.png',
        review:'I loved this trip with Rinku. He was so pleasant to be with and a great driver.  we felt very satisfied with what we saw. Would recommend this to anyone who wants to see India by car, it is truly worth it.',
    },
    {
        
        name:'Alberto',
        image:'/profile.png',
        ratingimg:'/star.png',
        review:'Incredible experience. Good driver, good conversation and nice car! A friend recommed him to me and I will do the same. Thanks Rinku!',
    },
]

export default Reviewslider
