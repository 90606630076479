import React from 'react'
import agra from '../rinkuuncle/img/agra.jpg'
import TAJMAHAL from '../rinkuuncle/img/TAJMAHAL.jpg'
import location from '../rinkuuncle/img/location.png'
import Popularcard from '../components/Popularcard'
import Footer from '../components/Footer'
import './Agra.css';
import Enquiryform from '../components/Enquiryform'
const Agra = () => {
  return (
    <div>
        {/* <!-- horizontal line --> */}
        <div className='horizontalline'></div>

       {/* <!-- [package heading] --> */}
        <h2  className='agrapackageheading'>
              AGRA DAY TRIP
        </h2>

        <p >
            <span ><img src={location} alt=""  style={{  height: "22px",}}></img></span> 

            <span style={{ }} className='agralocation'> Agra</span>
         </p>
      

        {/* <!-- horizontal line --> */}
        <div className='horizontalline'></div>

        {/* <!-- package details --> */}
         <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 ">
{/* <!-- package image --> */}
<div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{margintop: "20px",marginleft: "20px"}}>
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img src={TAJMAHAL} class="d-block w-100" alt="..." height="450px"></img>
      </div>
      <div className="carousel-item">
        <img src={agra} class="d-block w-100" alt="..."height="450px"></img>
      </div>
      <div className="carousel-item">
        <img src={TAJMAHAL} class="d-block w-100" alt="..."height="450px"></img>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
{/* <!-- tour itinerary heading --> */}
  <h3 style={{ }} className='agraitinerary'>TOUR ITINERARY</h3>
  {/* <!-- tour itinary content box --> */}
  <div className='contentbox'>
    <h4  style={{}} className='agraday1'>DAY-01 : Arrive Agra - on arrival visit the Agra Red Fort</h4>
    
        <p className='daycontent'>
            on arrival visit the Agra Red Fort which stands on the banks of the river Yamuna making its elf quite dominant in the whole area. It was designed and built by Akbar in 1565 .A.D. The Agra Fort is fortified by a 70-foot high wall. Within the precincts of Agra Red Fort is the beautiful Pearl Mosque and numerous palaces including the Jahangiri Mahal, Diwan-i-Am, Moti Masjid and Diwan-i-Khas. The fort is safeguarded by four gates and is enclosed by a double barricaded wall of red sandstone.
            <h6><b>After lunch, visit the Taj Mahal</b></h6>
            Described by the Indian classical poet Tagore as a 'tear on the face of eternity', the Taj Mahal is undoubtedly the zenith of Moghul architecture.An expression of undying love by Emperor Shah Jahan to immortalise his dead empress, Mumtaz Mahal, the satin smooth marble mausoleum was built over a period of 22 years from 1623 to 1645 A.D., using a work force of 22,000 artisans. It was meticulously planned and perfectly executed with carefully designed garden and adjacent buildings to compliment the entire environment. Time permitting, a brief visit to see the local craftsmen doing marble inlay work. 16.30 hrs: Leave Agra and drive to Delhi 21.30 hrs: Arrive Delhi and get dropped off at hotel or Airport for onward journey.
        </p>
  </div>
    </div>
    <div className="col-md-4 ">
       <Enquiryform></Enquiryform>
    </div>
    </div>
    </div>

    <div>
        <Popularcard></Popularcard>
    </div>

    <div>
        <Footer></Footer>
    </div>

    </div>
    
  )
}

export default Agra;